import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { API, Storage } from "aws-amplify";

function CompanyLogo({ title = '', attachment, description = '' }) {
  //const [attachmentURL, setAttachmentURL] = useState('/no-image.jpeg');
  const [attachmentURL, setAttachmentURL] = useState('');

  useEffect(() => {

    async function onLoad() {
      try {
        
        if (attachment.length > 0) {
          let newAttachmentURL = await Storage.vault.get(attachment, { level: 'public' });
          setAttachmentURL(newAttachmentURL);
        }
      } catch (e) {
        // onError(e);
      }
    }

    onLoad();

  }, [attachment]);

  return (
    // <img
    //   width="250"
    //   src={`${attachmentURL}?w=164&h=164&fit=crop&auto=format`}
    //   srcSet={`${attachmentURL}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
    //   alt={'title'}
    //   loading="lazy"
    // />

    <img
      width="250"
      src={attachmentURL}
      srcSet={attachmentURL}
      alt={'title'}
      loading="lazy"
      style={{marginTop: 8, maxHeight: 45}}
    />
  );
}

CompanyLogo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  attachment: PropTypes.string,
};

CompanyLogo.defaultProps = {
  title: '',
  description: '',
};

export default CompanyLogo;
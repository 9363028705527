import { createTranslations } from 'react-text-translator';

export const translator = createTranslations({
	'Hello World': {
		en: 'Hola mundo',
		fr: 'Hello World',
	},
	'Contact Us': {
		en: 'Contact Us',
		fr: 'Contáctanos',
	},
	'General Dashboard': {
		en: 'General Dashboard',
		fr: "Page d'accueil",
	},
	'Dashboard': {
		en: 'Dashboard',
		fr: "Accueil",
	},
	'Vendor Details Page': {
		en: 'Vendor Details Page',
		fr: 'Page Détails du vendeur',
	},
	'Active': {
		en: 'Active',
		fr: 'Active',
	},
	'Vendor Name': {
		en: 'Vendor Name',
		fr: 'Nom du vendeur',
	},
	'Email': {
		en: 'Email',
		fr: 'E-mail',
	},
	'Phone Number': {
		en: 'Phone Number',
		fr: 'Numéro de téléphone',
	},
	'Fax Number': {
		en: 'Fax Number',
		fr: 'Numéro de fax',
	},
	'Address Line 1': {
		en: 'Address Line 1',
		fr: 'Adresse 1',
	},
	'Address Line 2': {
		en: 'Address Line 2',
		fr: 'Adresse 2',
	},
	'City': {
		en: 'City',
		fr: 'Ville',
	},
	'Origin': {
		en: 'Origin',
		fr: 'Origine',
	},
	'ORIGIN': {
		en: 'ORIGIN',
		fr: 'ORIGINE',
	},
	'Product Receivable': {
		en: 'Product Receivable',
		fr: 'Produit à recevoir',
	},
	'Receive Product': {
		en: 'Receive Product',
		fr: 'Recevoir le produit',
	},
	'State': {
		en: 'State',
		fr: 'État',
	},
	'Zip Code': {
		en: 'Zip Code',
		fr: 'Code postal',
	},
	'Save': {
		en: 'SAVE',
		fr: 'ENREGISTRER',
	},
	'Vendors': {
		en: 'Vendors',
		fr: 'Vendeurs',
	},
	'Vendor': {
		en: 'Vendor',
		fr: 'Vendeur',
	},
	'Vendor Products': {
		en: 'Vendor Products',
		fr: 'Produits du vendeur',
	},
	'Main Vendor': {
		en: 'Main Vendor',
		fr: 'Vendeur principal',
	},

	'All Products': {
		en: 'All Products',
		fr: 'Tous les produits',
	},
	'Products': {
		en: 'Products',
		fr: 'Produits',
	},
	'Product': {
		en: 'Product',
		fr: 'Produit',
	},
	'PRODUCT': {
		en: 'PRODUCT',
		fr: 'PRODUIT',
	},
	'Manage Categories': {
		en: 'Manage Categories',
		fr: 'Gérer les catégories',
	},
	'Categories': {
		en: 'Categories',
		fr: 'Catégories',
	},
	'Create New Product': {
		en: 'Create New Product',
		fr: 'Créer un nouveau produit',
	},
	'Orders': {
		en: 'Orders',
		fr: 'Commandes',
	},
	'Order': {
		en: 'Order',
		fr: 'Commande',
	},
	'Customers': {
		en: 'Customers',
		fr: 'Clients',
	},
	'Customer': {
		en: 'Customer',
		fr: 'Client',
	},
	'Users': {
		en: 'Users',
		fr: 'Utilisateurs',
	},
	'User Roles': {
		en: 'User Roles',
		fr: "Rôles d'utilisateur",
	},
	'Settings': {
		en: 'Settings',
		fr: 'Réglages',
	},
	'Logout': {
		en: 'Logout',
		fr: 'Déconnecter',
	},
	'Pending Products': {
		en: 'Pending Products',
		fr: 'Produits en attente',
	},
	'Direct Request': {
		en: 'Depot Request',
		fr: 'Demande de Dépôt',

	},
	'Product Description': {
		en: 'Product Description',
		fr: 'Description',
	},
	'Packaging Number': {
		en: 'Packaging Number',
		fr: "Numéro d'emballage",
	},
	'Product Category': {
		en: 'Product Category',
		fr: 'Catégorie',
	},
	'Category': {
		en: 'Category',
		fr: 'Catégorie',
	},
	'Product Name': {
		en: 'Product Name',
		fr: 'Nom du produit',
	},
	'Length': {
		en: 'Length',
		fr: 'Longueur',
	},
	'Width': {
		en: 'Width',
		fr: 'Largeur',
	},
	'Height': {
		en: 'Height',
		fr: 'Hauteur',
	},
	'Unit Type': {
		en: 'Unit Type',
		fr: "Type d'unité",
	},

	'Unit Price': {
		en: 'Unit Price',
		fr: "Prix ​​Unitaire",
	},

	'Price': {
		en: 'Price',
		fr: 'Prix',
	},
	'Total Price': {
		en: 'Total Price',
		fr: 'Prix',
	},

	'Total Cost': {
		en: 'Total Cost',
		fr: 'Coût Total',
	},

	'Product cost': {
		en: 'Product cost',
		fr: 'Coût du produit',
	},

	'PRICE': {
		en: 'PRICE',
		fr: 'PRIX',
	},
	'Quantity': {
		en: 'Quantity',
		fr: 'Quantité',
	},
	'QUANTITY': {
		en: 'QUANTITY',
		fr: 'QUANTITÉ',
	},
	'Quantity Type': {
		en: 'Quantity Type',
		fr: 'Type de quantité',
	},
	'Quantity Left': {
		en: 'Quantity Left',
		fr: 'Quantité restante',
	},
	'Quantity Alert': {
		en: 'Quantity Alert',
		fr: 'Alerte de quantité',
	},
	'Page Title': {
		en: 'Page Title',
		fr: 'Titre de la page',
	},
	'Meta Description': {
		en: 'Meta Description',
		fr: 'Meta Description',
	},
	'Last Origin': {
		en: 'Last Origin',
		fr: 'Dernière origine',
	},
	'Last Received Date': {
		en: 'Last Received Date',
		fr: 'Date de dernière réception',
	},
	'INVENTORY': {
		en: 'INVENTORY',
		fr: 'INVENTAIRE',
	},
	'Inventory': {
		en: 'Inventory',
		fr: 'Inventaire',
	},
	'SKU': {
		en: 'SKU',
		fr: 'Référence du produit',
	},
	'Bar Code': {
		en: 'Bar Code',
		fr: 'Code à barre',
	},
	'Weight (kg)': {
		en: 'Weight (kg)',
		fr: 'Poids (kg)',
	},
	'ATTRIBUTES': {
		en: 'ATTRIBUTES',
		fr: 'LES ATTRIBUTS',
	},
	'ADDITIONAL INFO': {
		en: 'ADDITIONAL INFO',
		fr: 'INFORMATION ADDITIONNELLE',
	},
	'Position': {
		en: 'Position',
		fr: 'Position',
	},
	'PRODUCT DISPATCH': {
		en: 'PRODUCT DISPATCH',
		fr: 'EXPÉDITION',
	},
	'IMAGES': {
		en: 'IMAGES',
		fr: 'IMAGES',
	},
	'Dispatch/Received History': {
		en: 'Dispatch/Received History',
		fr: 'Historique des envois/réceptions',
	},
	//Dispatch/Received History
	'Pricing': {
		en: 'Pricing',
		fr: 'Tarification',
	},
	'Regular Price': {
		en: 'Regular Price',
		fr: 'Prix ​​habituel',
	},
	'Sales Price': {
		en: 'Sales Price',
		fr: 'Prix ​​de vente',
	},
	'Sale From': {
		en: 'Sale From',
		fr: 'Vente à partir de',
	},
	'Sale To': {
		en: 'Sale To',
		fr: 'Vente à',
	},
	'Stock Settings': {
		en: 'Stock Settings',
		fr: 'Paramètres des stocks',
	},
	'Stock Management': {
		en: 'Stock Management',
		fr: 'Gestion de stock',
	},
	'Is Preorder': {
		en: 'Is Preorder',
		fr: 'Est en précommande',
	},
	'Allow Backorders': {
		en: 'Allow Backorders',
		fr: 'Autoriser les commandes en souffrance',
	},

	'Discontinued': {
		en: 'Discontinued',
		fr: 'Discontinué',
	},
	//Allow Backorders

	'New Business Year': {
		en: 'New Business Year',
		fr: 'Nouvelle année commerciale',
	},
	'Business Year': {
		en: 'Business Year',
		fr: 'Année commerciale',
	},

	'Actions': {
		en: 'Actions',
		fr: 'Actions',
	},
	'View': {
		en: 'View',
		fr: 'Voir',
	},
	'Stock': {
		en: 'Stock',
		fr: 'Stocker',
	},
	'BL Number': {
		en: 'BL Number',
		fr: 'BL Number',
	},
	'BL NUMBER': {
		en: 'BL NUMBER',
		fr: 'BL NUMBER',
	},
	'Status': {
		en: 'Status',
		fr: 'Statut',
	},
	'Created Date': {
		en: 'Created Date',
		fr: 'Date de création',
	},
	'selected': {
		en: 'selected',
		fr: 'choisi',
	},
	'Receive': {
		en: 'RECEIVE',
		fr: 'RECEVOIR',
	},
	'Other Products': {
		en: 'Other Products',
		fr: 'Autres Produits',
	},
	'Available Products': {
		en: 'Available Products',
		fr: 'Produits Disponibles',
	},
	'TRANSFER': {
		en: 'TRANSFER',
		fr: 'TRANSFÉRER',
	},
	'Product Transfer': {
		en: 'Product Transfer',
		fr: 'Transfert de produit',
	},
	'Product by vendor': {
		en: 'Product by vendor',
		fr: 'Produit par vendeur',
	},
	'Transfer Date': {
		en: 'Transfer Date',
		fr: 'Date de transfert',
	},
	'Transfer To': {
		en: 'Transfer To',
		fr: 'Transférer à',
	},
	'Transfer Reason': {
		en: 'Transfer Reason',
		fr: 'Raison du transfert',
	},
	'Approver': {
		en: 'Approver',
		fr: 'Approbateur',
	},
	'Approved By': {
		en: 'Approved By',
		fr: 'Approuvé Par',
	},
	'Not enough products in stock': {
		en: 'Not enough products in stock!',
		fr: 'Pas assez de produits en stock!',
	},
	'REQUEST': {
		en: 'VENDOR REQUEST',
		fr: 'DEMANDE DU VENDEUR',
	},
	'shipped': {
		en: 'shipped',
		fr: 'expédié',
	},
	'Request Product Transfer': {
		en: 'Request Product Transfer',
		fr: 'Demander un transfert de produit',
	},
	'Cancel Request': {
		en: 'CANCEL REQUEST',
		fr: "DEMANDE D'ANNULATION",
	},
	'Edit': {
		en: 'EDIT',
		fr: "MODIFIER",
	},
	'Transferred From': {
		en: 'Transferred From',
		fr: "Transféré de",
	},
	'Transferred To': {
		en: 'Transferred To',
		fr: "Transféré à",
	},
	'Received Date': {
		en: 'Received Date',
		fr: "Date de réception",
	},
	'received': {
		en: 'RECEIVED',
		fr: "REÇU",
	},
	'requested': {
		en: 'REQUESTED',
		fr: "DEMANDÉ",
	},
	'declined': {
		en: 'DECLINED',
		fr: "REFUSEE",
	},
	'confirmed': {
		en: 'CONFIRMED',
		fr: "CONFIRMÉ",
	},
	'confirm': {
		en: 'CONFIRM',
		fr: "CONFIRMER",
	},
	'accepted': {
		en: 'ACCEPTED',
		fr: "ACCEPTÉ",
	},
	'Dispatch': {
		en: 'Dispatch',
		fr: "Expédition",
	},
	'Date': {
		en: 'Date',
		fr: "Date",
	},
	'Product Transfer History': {
		en: 'Product Transfer History',
		fr: 'Historique des transferts de produits',
	},
	'Sales this week': {
		en: 'Sales this week',
		fr: 'Soldes cette semaine',
	},
	'Sales by month': {
		en: 'Sales by month',
		fr: 'Ventes par mois',
	},
	'Pending Product Transfers': {
		en: 'Pending Product Transfers',
		fr: 'Transferts de produits en attente',
	},
	'My Product Transfers': {
		en: 'My Product Transfers',
		fr: 'Mes transferts de produits',
	},
	'Vendors Transfer Requests': {
		en: 'Vendors Transfer Requests',
		fr: 'Demandes de transfert de vendeurs',
	},
	'Accept/Decline': {
		en: 'Accept/Decline',
		fr: 'Accepter/Refuser',
	},
	'remove': {
		en: 'Remove',
		fr: 'Retirer',
	},
	'Remove': {
		en: 'Remove',
		fr: 'Retirer',
	},
	'Select Category': {
		en: 'Select Category',
		fr: 'Choisir une catégorie',
	},
	'ADD NEW ATTRIBUTE': {
		en: 'ADD NEW ATTRIBUTE',
		fr: 'AJOUTER UN NOUVEL ATTRIBUT',
	},
	'Add Attribute': {
		en: 'Add Attribute',
		fr: 'Ajouter un attribut',
	},
	'Add': {
		en: 'Add',
		fr: 'Ajouter',
	},
	'Attribute Name': {
		en: 'Attribute Name',
		fr: "Nom d'attribut",
	},
	'Attribute Value': {
		en: 'Attribute Value',
		fr: "Valeur d'attribut",
	},
	'Tags': {
		en: 'Tags',
		fr: 'Tags',
	},
	'Related Products': {
		en: 'Related Products',
		fr: 'Produits connexes',
	},
	'Product Search': {
		en: 'Product Search',
		fr: 'Recherche de produit',
	},
	'Search': {
		en: 'Search',
		fr: 'Recherche',
	},
	'Create Product': {
		en: 'Create Product',
		fr: 'Créer un produit',
	},
	//
	'Additional Categories': {
		en: 'Additional Categories',
		fr: 'Catégories supplémentaires',
	},
	'Select Other Categories': {
		en: 'Select Other Categories',
		fr: "Sélectionner d'autres catégories",
	},
	//
	'Requested By': {
		en: 'Requested By',
		fr: 'Demandé par',
	},
	'accepted by': {
		en: 'ACCEPTED BY: ',
		fr: 'ACCEPTÉ PAR: ',
	},
	'Accepted By': {
		en: 'Accepted By: ',
		fr: 'Accepté Par: ',
	},
	'Accepted Date': {
		en: 'Accepted Date',
		fr: "Date d'acceptation",
	},
	'FROM': {
		en: 'FROM',
		fr: 'DE',
	},
	'TO': {
		en: 'TO',
		fr: 'POUR',
	},
	'from': {
		en: 'FROM',
		fr: 'DE',
	},

	'Requested Date': {
		en: 'Requested Date',
		fr: 'Date demandée',
	},

	'DECLINE REQUEST': {
		en: 'DECLINE REQUEST',
		fr: 'REFUSER LA DEMANDE',
	},

	'Closed Orders Today': {
		en: 'Closed Orders Today',
		fr: "Fermé aujourd'hui",
	},
	'Draft Orders': {
		en: 'Draft Orders',
		fr: 'Préparer',
	},
	'Today Sales': {
		en: 'Today Sales',
		fr: "Les ventes d'aujourd'hui",
	},
	'Sales this Month': {
		en: 'Sales this Month',
		fr: 'Les ventes du mois',
	},
	'Product Details Page': {
		en: 'Product Details Page',
		fr: 'Page Détails du produit',
	},
	'Product Details': {
		en: 'Product Details',
		fr: 'Détails du produit',
	},
	'Available Quantity': {
		en: 'Total Quantity',
		fr: 'Quantité totale',
	},
	'All Quantity': {
		en: 'Quantity',
		fr: 'Quantité',
	},
	'Suppliers': {
		en: 'Suppliers',
		fr: 'Fournisseurs',
	},
	'Cashier': {
		en: 'Cashier',
		fr: 'La caissière',
	},
	'Cashier Balance': {
		en: 'CASHIER BALANCE',
		fr: 'SOLDE EN CAISSE',
	},
	'Total Amount': {
		en: 'TOTAL AMOUNTS',
		fr: 'MONTANTS',
	},
	'Amounts': {
		en: 'AMOUNTS',
		fr: 'MONTANTS',
	},
	'Amount': {
		en: 'Amount',
		fr: 'Montant',
	},
	'Exits': {
		en: 'OUTPUTS',
		fr: 'SORTIES',
	},
	'exits': {
		en: 'Shipping',
		fr: 'Sorties',
	},
	'OUTPUTS': {
		en: 'OUTPUTS',
		fr: 'SORTIES',
	},
	'Outputs': {
		en: 'Outputs',
		fr: 'Sorties',
	},

	'Order Outputs': {
		en: 'Order Outputs',
		fr: 'Sorties de Commande',
	},

	'Manage Outputs': {
		en: 'Manage Outputs',
		fr: 'Gérer les Sorties',
	},

	'Add Output': {
		en: 'Add Output',
		fr: 'Ajouter Une Sortie',
	},

	'Update Output': {
		en: 'Update Output',
		fr: 'Mettre à jour la sortie',
	},

	'Total Credit': {
		en: 'CREDITS',
		fr: 'CRÉDITS',
	},
	'Credits': {
		en: 'Credits',
		fr: 'Crédits',
	},
	'Payment': {
		en: 'DEPOSIT',
		fr: 'VERSEMENT',
	},
	'DEPOSITS': {
		en: 'DEPOSITS',
		fr: 'VERSEMENT',
	},
	'Deposits': {
		en: 'Deposits',
		fr: 'Versement',
	},
	'Cashier Totals': {
		en: 'Totals',
		fr: 'Totaux',
	},
	'Apply Filter': {
		en: 'Apply Filter',
		fr: 'Appliquer le filtre',
	},
	'from date': {
		en: 'From',
		fr: 'à partir de',
	},
	'to date': {
		en: 'To',
		fr: 'à',
	},
	'Order Date': {
		en: 'Order Date',
		fr: 'Date de commande',
	},
	'Order Status': {
		en: 'Order Status',
		fr: 'Statut de la commande',
	},
	'Tracking Number': {
		en: 'Tracking Number',
		fr: 'Numéro de suivi',
	},
	'Shipping Method': {
		en: 'Delivery Method',
		fr: 'Méthode de Livraison',
	},
	'Delivery Fee': {
		en: 'Delivery Fee',
		fr: 'Frais de Livraison',
	},
	'Payment Method': {
		en: 'Payment Method',
		fr: 'Mode de paiement',
	},
	'Note': {
		en: 'Note',
		fr: 'Note',
	},
	'Order Details': {
		en: 'Order Details',
		fr: 'Détails de la commande',
	},
	'Shipping Cost': {
		en: 'Shipping Cost',
		fr: 'Frais de livraison',
	},
	'Customer Information': {
		en: 'Customer Information',
		fr: 'Informations sur le client',
	},
	'Customer Name': {
		en: 'Customer Name',
		fr: 'Nom du Client',
	},
	'Customer Phone Number': {
		en: 'Customer Phone Number',
		fr: 'Numéro de Téléphone du Client',
	},
	'Customer Email': {
		en: 'Customer Email',
		fr: 'E-mail du client',
	},
	'Shipping Address': {
		en: 'Shipping Address',
		fr: 'Adresse de livraison',
	},
	'Address Line One': {
		en: 'Address Line One',
		fr: 'Adresse ligne 1',
	},
	'Address Line Two': {
		en: 'Address Line Two',
		fr: 'Adresse ligne 2',
	},
	'Order Details Page': {
		en: 'Order Details Page',
		fr: 'Page de détails de la commande',
	},
	'Cancelled': {
		en: 'Cancelled',
		fr: 'Annulé',
	},
	'Closed': {
		en: 'Closed',
		fr: 'Fermé',
	},
	'Hold': {
		en: 'Hold',
		fr: 'Attendez',
	},
	'Placed': {
		en: 'Placed',
		fr: 'Commande Passée',
	},
	'Cancel': {
		en: 'Cancel',
		fr: 'Annuler',
	},
	'Add Product': {
		en: 'Add Product',
		fr: 'Ajouter un produit',
	},
	'Draft': {
		en: 'Draft',
		fr: 'Préparer',
	},
	'Print Out Invoice': {
		en: 'Print Out Invoice',
		fr: 'Imprimer la facture',
	},
	'Invoice': {
		en: 'Invoice',
		fr: 'Facture',
	},

	'Invoices': {
		en: 'Invoices',
		fr: 'Factures',
	},

	'Expenses': {
		en: 'Expenses',
		fr: 'Dépenses',
	},

	'Order Number': {
		en: 'Order Number',
		fr: 'Numéro de commande',
	},
	'DESCRIPTION': {
		en: 'DESCRIPTION',
		fr: 'DESCRIPTION',
	},
	'TOTAL': {
		en: 'TOTAL',
		fr: 'TOTAL',
	},
	'Total': {
		en: 'Total',
		fr: 'Total',
	},
	'No product added': {
		en: 'No product added',
		fr: 'Aucun produit ajouté',
	},
	'Subtotal': {
		en: 'Subtotal',
		fr: 'Total',
	},
	'Tax': {
		en: 'Tax',
		fr: 'TVA',
	},
	'Grand Total': {
		en: 'Grand Total',
		fr: 'Total Général',
	},
	'Fees and Credits': {
		en: 'Fees and Credits',
		fr: 'Frais et crédits',
	},
	'Shipping Status': {
		en: 'Shipping Status',
		fr: "Statut d'envoi",
	},
	'Order Items': {
		en: 'Order Items',
		fr: "Items commandés",
	},
	'Description': {
		en: 'Description',
		fr: "Description",
	},
	'Add Deposit': {
		en: 'Add Deposit',
		fr: "Ajouter un Versement",
	},
	'Update Deposit': {
		en: 'Update Deposit',
		fr: "Mettre à jour le dépôt",
	},
	'Bank Account': {
		en: 'Bank Account',
		fr: "Compte Bancaire",
	},
	'Deposited Amount': {
		en: 'Deposited Amount',
		fr: "Montant Déposé",
	},
	'Deposited Amount re': {
		en: 'Deposited Amount *',
		fr: "Montant Déposé *",
	},
	'Deposited Date': {
		en: 'Deposited Date',
		fr: "Date de Dépôt",
	},
	'Deposited Date re': {
		en: 'Deposited Date *',
		fr: "Date de Dépôt *",
	},
	'Deposited By': {
		en: 'Deposited By',
		fr: "Déposé Par",
	},
	'Deposited By re': {
		en: 'Deposited By *',
		fr: "Déposé Par *",
	},
	'Deposited To': {
		en: 'Deposited To',
		fr: "Déposé à",
	},

	'Deposit Method': {
		en: 'Deposit Methody',
		fr: "Méthode de Dépôt",
	},
	'Deposit Method re': {
		en: 'Deposit Methody *',
		fr: "Méthode de Dépôt *",
	},
	'Confirm Remove': {
		en: 'Confirm Remove',
		fr: "Confirmer la Suppression",
	},
	'Are you sure': {
		en: 'Are you sure you want to remove this record.',
		fr: "Êtes-vous sûr de vouloir supprimer cet enregistrement.",
	},
	'Manage': {
		en: 'Manage',
		fr: 'Gérer',
	},
	'Product Dispatch History': {
		en: 'Product Dispatch History',
		fr: 'Expédition Historique',
	},
	'Product Received History': {
		en: 'Product Received History',
		fr: 'Historique des produits reçus',
	},
	'Customer Credit': {
		en: "Customer's Credit",
		fr: 'Crédit du Client',
	},
	'Payments': {
		en: "Payments",
		fr: 'Paiements',
	},
	"Payments and Invoices": {
		en: "Payments/Invoices",
		fr: "Paiements/Factures",
	},
	'No payment yet': {
		en: "No payment yet.",
		fr: 'Pas encore de paiement.',
	},
	'Amount Due': {
		en: "Amount Due",
		fr: 'Crédit du Client',
	},
	'Manage Order Info': {
		en: "Manage Order Info",
		fr: 'Gérer Les Informations De Commande',
	},
	'ADD TRANSACTION': {
		en: "ADD TRANSACTION",
		fr: 'AJOUTER UNE TRANSACTION',
	},
	'Transaction Amount': {
		en: "Transaction Amount",
		fr: 'Montant De La Transaction',
	},

	'Payment Date': {
		en: "Payment Date",
		fr: 'Date De Paiement',
	},

	'Created By': {
		en: "Created By",
		fr: 'Créé Par',
	},
	'Last Updated By': {
		en: "Last Updated By",
		fr: 'Dernière mise à jour par',
	},


	'Search For Customer': {
		en: "Search For Customer",
		fr: 'Rechercher Un Client',
	},

	'Fully Paid': {
		en: "Fully Paid",
		fr: 'Entièrement Payé',
	},

	'Customer Account Number': {
		en: "Customer Account Number",
		fr: 'Numéro De Compte Client',
	},

	'Full Name': {
		en: "Full Name",
		fr: 'Nom et Prénom',
	},

	'Customer Address': {
		en: "Customer Address",
		fr: 'Adresse du Client',
	},

	'Customer Orders': {
		en: "Customer Orders",
		fr: 'Commandes du Client',
	},

	'select all': {
		en: "select all",
		fr: 'tout sélectionner',
	},

	'New Customer': {
		en: "New Customer",
		fr: 'Nouveau Client',
	},

	'Pending Approvals': {
		en: "Pending Approvals",
		fr: 'Approbations en Attente',
	},
	'pending': {
		en: "PENDING",
		fr: 'EN ATTENTE',
	},

	'Pending Deposits': {
		en: "Pending Deposits",
		fr: 'Versements en Attente',
	},

	'Approve': {
		en: "Approve",
		fr: 'Approuver',
	},

	'Decline': {
		en: "Decline",
		fr: 'Déclin',
	},

	'processing': {
		en: "processing",
		fr: 'en cours',
	},

	'Tax Included': {
		en: "Tax Included",
		fr: 'TVA Inclu',
	},

	'Type': {
		en: "Type",
		fr: 'Type',
	},

	"Today Orders": {
		en: "Today's Orders",
		fr: 'Les commandes du jour',
	},

	"My Store": {
		en: "My Store",
		fr: "Mon Magasin",
	},

	"Switch Store": {
		en: "Switch Store",
		fr: "Changer de Magasin",
	},

	"REFRESH": {
		en: "REFRESH",
		fr: "RECHARGER",
	},

	"BACK TO MY STORE": {
		en: "BACK TO MY STORE",
		fr: "RETOUR À MON MAGASIN",
	},

	"Create New Order": {
		en: "Create New Order",
		fr: "Créer une Nouvelle Commande",
	},
	"Change password": {
		en: "Your password has been changed",
		fr: "Votre mot de passe a été changé",
	},
	"Wrong password": {
		en: "Current password incorrect",
		fr: "mot de passe actuelle incorrect",
	},
	"Name": {
		en: "Name",
		fr: "Nom",
	},
	"User Email": {
		en: "User Email",
		fr: "Nom d'utilisateur",
	},
	"Phone": {
		en: "Phone",
		fr: "Téléphone",
	},
	"Change password": {
		en: "Change password",
		fr: "Changer mot de passe",
	},
	"Password change": {
		en: "Password change",
		fr: "Changement du mot de passe",
	},
	"Current password": {
		en: "Current password",
		fr: "Mot de passe actuelle",
	},
	"Current password": {
		en: "Current password",
		fr: "Mot de passe actuelle",
	},
	"Current password is required": {
		en: "Current password is required",
		fr: "Le mot de passe actuelle est obligatoire",
	},
	"New password": {
		en: "New password",
		fr: "Nouveau mot de passe",
	},
	"Password is required": {
		en: "Password is required",
		fr: "Le mot de passe est obligatoire"
	},
	"Confirm password": {
		en: "Confirm password",
		fr: "Confirmer mot de passe",
	},
	"Passwords are not the same": {
		en: "Passwords are not the same",
		fr: "Les mots de passe ne correspondent pas"
	},
	"Validate": {
		en: "Validate",
		fr: "Valider",
	},
	"Account Information": {
		en: "Account Information",
		fr: "Information du compte",
	},
	"Spending Type": {
		en: "Spending Type",
		fr: "Type de dépenses",
	},
	"loan": {
		en: "Loan",
		fr: "Prêt",
	},
	"Loan For": {
		en: "Loan For",
		fr: "Prêt pour",
	},
	"vendor": {
		en: "Vendor",
		fr: "Vendeur",
	},
	"vehicle": {
		en: "Vehicle",
		fr: "Véhicule",
	},
	"Returned Items": {
		en: "Returned Items",
		fr: "Articles retournés",
	},
	"Shipments": {
		en: "Shipments",
		fr: "Expéditions",
	},

});

export const Text = translator.Text;
import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useHistory, useParams } from "react-router-dom";
import { Auth, API } from "aws-amplify";

import validator from 'validator';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import InputMask from "react-input-mask";

//import LoaderButton from "../../components/LoaderButton";
import { useAppContext } from "../../lib/contextLib";
import { useFormFields } from "../../lib/hooksLib";
import TransferList from "../../components/forms/TransferList";
import { s3Upload } from "../../lib/awsLib";
import LoaderButton from "../../components/LoaderButton";
import { onError } from "../../lib/errorLib";
import {
    saveUser,
    getUser,
    updateUser
} from "../../services/users";
import { areacodes } from "../../services/country_phone_areacodes";
import {
    getUserRole
} from "../../services/user_roles";
import { getAllVendors } from "../../services/vendors";
import { getAllUserRoles } from "../../services/user_roles";

import "./Signup.css";
//import { ConfigurationServicePlaceholders } from "aws-sdk/lib/config_service_placeholders";

const styles = {
    whiteBox: {
        padding: 20,
        backgroundColor: '#FFFFFF',
        borderRadius: 5
    }
};

export default function Signup({ userId = null, type = '' }) {
    const [fields, handleFieldChange] = useState({
        companyId: "",
        userId: "",
        firstName: "",
        middleName: "",
        lastName: "",
        phoneNumber: "",
        phoneCountryCode: "221",
        username: "",
        email: "",
        roleId: "",
        password: "",
        userPermissions: "",
        confirmPassword: ""
        // confirmationCode: "",
    });

    const { id } = useParams();
    const [updated, setUpdated] = useState(new Date());
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);
    const [showSave, setShowSave] = useState(false);
    const [accountOwner, setAccountOwner] = useState(false);
    const [active, setActive] = useState(true);
    const [isCompanyAdmin, setIsCompanyAdmin] = useState(false);
    const [vendorId, setVendor] = useState('');
    const [role, setRole] = useState('');
    const history = useHistory();
    const {
        userHasAuthenticated,
        store,
        actions,
        roles,
        vendors,
        permissions,
        vendor,
        user,
        userPermissions,
        companyId,
        isSupperAdmin
    } = useAppContext();

    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({
        companyId: "",
        isCompanyAdmin: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        phoneCountryCode: "",
        userId: "",
        vendorId: "",
        roleId: "",
        email: "",
        username: "",
        password: "",
        confirmPassword: "",
    });

    function updateErrors(name) {

        let newErrors = {
            ...errors,
            [name]: ''
        };

        setErrors(newErrors);

    }

    function validateForm() {
        let newErrors = { ...errors };
        let isValid = 1;

        if (vendorId == '' && type !== 'admin') {
            newErrors.vendorId = 'Vendor is required.';
        } else {
            newErrors.vendorId = '';
        }

        if (fields.firstName == '') {
            newErrors.firstName = 'First name is required.';
        } else {
            newErrors.firstName = '';
        }

        if (fields.lastName == '') {
            newErrors.lastName = 'Last name is required.';
        } else {
            newErrors.lastName = '';
        }

        if (fields.phoneNumber == '') {
            newErrors.phoneNumber = 'Phone number is required.';
        } else {
            newErrors.phoneNumber = '';
        }

        if (id == undefined) {
            if (role == '' && type !== 'admin') {
                newErrors.roleId = 'Role is required.';
            } else {
                newErrors.roleId = '';
            }

            if (fields.password == '') {
                newErrors.password = 'Password is required.';
            } else {
                newErrors.password = '';
            }

            if (fields.confirmPassword == '') {
                newErrors.confirmPassword = 'Confirm Password is required.';
            } else {

                if (fields.confirmPassword !== fields.password) {
                    newErrors.confirmPassword = 'Password did not match.';
                } else {
                    newErrors.confirmPassword = '';
                }
            }
        }

        if (fields.email.length < 0) {
            newErrors.email = 'Email address is required.';
        } else {
            if (validator.isEmail(fields.email)) {
                newErrors.email = '';
            } else {
                newErrors.email = 'Please Enter an valid Email Address.';
            }
        }

        setErrors(newErrors);

        if (
            newErrors.firstName.length > 0 ||
            newErrors.lastName.length > 0 ||
            newErrors.phoneNumber.length > 0 ||
            newErrors.email.length > 0 ||
            newErrors.password.length > 0 ||
            newErrors.confirmPassword.length > 0 ||
            newErrors.vendorId.length > 0 ||
            newErrors.roleId.length > 0
        ) {
            isValid = 0;
        }

        return isValid;
    }

    useEffect(() => {
        async function onLoad() {
            try {

                if (user.userId === id) {
                    setAccountOwner(true);
                }

            } catch (e) {
                onError(e);
            }
        }
        onLoad();
    }, []);


    function handleInputChange(event) {

        let { name, value } = event.target;

        let newFormInput = {
            ...fields,
            [name]: value
        };

        handleFieldChange({ ...newFormInput });
        updateErrors(name);
        setShowSave(true);
    }

    async function handleSubmit() {

        let isValid = validateForm();

        try {
            if (isValid) {

                setShowSave(false);
                if (id != undefined || userId != null) {

                    if (accountOwner && (fields.email != user.email || fields.username != user.username)) {// Allow account owners to change
                        await updateUserInfo(fields.email, fields.username);
                    }

                    if (type !== 'admin') {
                        await updateUser(id || userId, { ...fields, active, vendorId, roleId: role });
                    }
                    
                    actions.onSetFeedback(true, 'User was successfully updated.', 'success');

                } else {
                    const newUserId = companyId + new Date().getTime() + Math.random();
                    let attributes = {};

                    if(type !== 'admin'){
                        attributes = {
                            email: fields.email, // optional
                            phone_number: "+" + fields.phoneCountryCode + fields.phoneNumber,
                            'custom:companyId': companyId,
                            'custom:userId': newUserId,
                            'custom:vendorId': vendorId,
                        }
                    }else{
                        attributes = {
                            email: fields.email, // optional
                            phone_number: "+" + fields.phoneCountryCode + fields.phoneNumber,
                            'custom:isAdmin': "1"
                        }
                    }

                    const { user } = await Auth.signUp({
                        username: fields.username,
                        password: fields.password,
                        attributes: {
                            ...attributes
                        }
                    });

                    if (type !== 'admin') {
                        let newCreatedUser = await createUser(newUserId);
                        if (!isSupperAdmin) {
                            history.push("/a_portal/users/" + newCreatedUser.userId);
                        }
                    }
                    actions.onSetIsLoading(false);
                    actions.onSetFeedback(true, 'User was successfully created.', 'success');
                }

                actions.onGetUsers(companyId);
            }

        } catch (e) {
            actions.onSetIsLoading(false);
            onError(e);

        }
    }

    async function createUser(userId) {
        try {
            const res = await saveUser({
                ...fields,
                companyId,
                isCompanyAdmin,
                userId,
                active,
                vendorId,
                roleId: role
            });
            return res;
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    }

    async function handleTransferChange(rightData, leftData) {

        let newFormInput = {
            ...fields,
            ['userPermissions']: rightData
        };
        setLeft(leftData);
        setRight(rightData);
        handleFieldChange({ ...newFormInput });
    }

    useEffect(async () => {
        if (id != undefined || userId != null) {
            let user = await getUser(id || userId);
            setRole(user.roleId);
            setActive(user.active);
            setIsCompanyAdmin(user.isCompanyAdmin);
            setVendor(user.vendorId);
            handleFieldChange(user);
            setRight(user.userPermissions || []);
            await getUserPermission(user.userPermissions || []);
        } else {
            setLeft(permissions);
        }
    }, [id, userId, permissions]);

    useEffect(async () => {
        setUpdated(Date.now());
    }, [left, right]);

    async function getUserPermission(rightData) {
        let newLeft = [];

        try {

            permissions.forEach(permission => {
                if (permission.permissionValue) {

                    let per = rightData.filter((item) => item.permissionValue == permission.permissionValue);

                    if (per.length <= 0) {
                        newLeft.push(permission);
                    }
                }

            });

            setLeft(newLeft);
            setTimeout(() => {
                setShowSave(false);
            }, 1000);

        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        actions.onSetSelected('users');
    }, []);

    async function getRolePermissions(roleID) {
        let role = await getUserRole(roleID);
        const newUserPermissions = role.userPermissions || [];
        const leftData = [];
        const rightData = [];

        let newFormInput = {
            ...fields,
            ['userPermissions']: role.userPermissions || []
        };

        permissions.forEach(permission => {

            if (permission.permissionValue) {

                let per = newUserPermissions.filter((item) => item.permissionValue == permission.permissionValue);

                if (per.length <= 0) {
                    leftData.push(permission);
                } else {
                    rightData.push(permission);
                }

            }

        });

        setRight(rightData);
        setLeft(leftData);
        setShowSave(true);
        handleFieldChange({ ...newFormInput });
    }


    async function updateUserInfo(email, username) {
        try {
            const currentUser = await Auth.currentAuthenticatedUser();
            const result = await Auth.updateUserAttributes(currentUser, {
                email: email,
                username: username
            });
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="Signup"
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                //maxWidth: 500
            }}
        >

            <Box
                sx={{
                    // flexGrow: 1,
                    // display: 'flex',
                    // alignItems: 'center',
                    // justifyContent: 'center',
                    maxWidth: 1000
                }}
                style={styles.whiteBox} >
                <h2 className="pb-3 mb-3 border-bottom"> {id == undefined ? 'User Create Form' : 'User Update Form'} {id == undefined ? "" : ' - ' + fields.userAccountNumber}</h2>

                <Grid container spacing={1}>

                    <Grid container item spacing={3}>

                        <Grid item xs={12} sm={6} md={6}>

                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography>Active</Typography>
                                <Switch
                                    disabled={accountOwner && !userPermissions.admin && !isSupperAdmin ? true : false}
                                    checked={active}
                                    onChange={(e) => {
                                        setActive(e.target.checked);
                                        setShowSave(true);
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </Stack>

                        </Grid>

                        {type !== 'admin' &&
                            <Grid item xs={12} sm={6} md={6}>

                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography>Company Admin</Typography>
                                    <Switch
                                        disabled={!isSupperAdmin ? true : false}
                                        checked={isCompanyAdmin}
                                        onChange={(e) => {
                                            setIsCompanyAdmin(e.target.checked);
                                            setShowSave(true);
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </Stack>

                            </Grid>
                        }

                    </Grid>

                    <Grid container item spacing={3}>

                        <Grid item xs={12} sm={6} md={6}>

                            <TextField
                                error={errors.firstName !== '' ? true : false}
                                id="outlined-error"
                                name="firstName"
                                helperText={errors.firstName}
                                label="First Name"
                                value={fields.firstName}
                                onChange={handleInputChange}
                                fullWidth
                            />

                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>

                            <TextField
                                error={errors.lastName !== '' ? true : false}
                                id="outlined-error"
                                name="lastName"
                                helperText={errors.lastName}
                                label="Last Name"
                                value={fields.lastName}
                                onChange={handleInputChange}
                                fullWidth
                            />

                        </Grid>

                    </Grid>

                    <Grid container item spacing={3}>

                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                                disabled={id != undefined && !accountOwner ? true : false}
                                error={errors.username !== '' ? true : false}
                                helperText={errors.username}
                                id="outlined-error"
                                name="username"
                                label="Username"
                                value={fields.username}
                                fullWidth
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                                disabled={id != undefined && !accountOwner ? true : false}
                                error={errors.email !== '' ? true : false}
                                helperText={errors.email}
                                id="outlined-error"
                                name="email"
                                label="Email"
                                value={fields.email}
                                fullWidth
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid container item spacing={3}>

                        <Grid item xs={12} sm={6} md={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Phone Country Code</InputLabel>
                                <Select
                                    disabled={false}
                                    error={errors.phoneCountryCode !== '' ? true : false}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="phoneCountryCode"
                                    value={fields.phoneCountryCode}
                                    label="Phone Country Code"
                                    onChange={handleInputChange}
                                >
                                    {areacodes.map((c, index) =>
                                        <MenuItem key={index} value={c.code}>{c.country} <small>(+{c.code})</small></MenuItem>
                                    )}

                                </Select>
                            </FormControl>
                        </Grid>


                        <Grid item xs={12} sm={6} md={6}>
                            {/* <InputMask
                                // mask="99 999 99 99"
                                value={fields.phoneNumber}
                                disabled={false}
                                maskChar=" "
                                onChange={handleInputChange}
                            >
                                {() =>
                                    <TextField
                                        error={errors.phoneNumber !== '' ? true : false}
                                        id="outlined-error"
                                        name="phoneNumber"
                                        helperText={errors.phoneNumber}
                                        label="Phone Number"
                                        fullWidth
                                    />}
                            </InputMask> */}
                            <TextField
                                error={errors.phoneNumber !== '' ? true : false}
                                helperText={errors.phoneNumber}
                                id="outlined-error"
                                name="phoneNumber"
                                label="Phone Number"
                                value={fields.phoneNumber}
                                fullWidth
                                onChange={handleInputChange}
                            />

                        </Grid>

                    </Grid>



                    {type !== 'admin' && <Grid container item spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Vendor</InputLabel>
                                <Select
                                    disabled={accountOwner && !userPermissions.admin && !isSupperAdmin ? true : false}
                                    error={errors.vendorId !== '' ? true : false}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="vendorId"
                                    value={vendorId}
                                    label="Vendor"
                                    onChange={(e) => {
                                        //handleInputChange(e);
                                        setVendor(e.target.value);
                                    }}
                                >
                                    {vendors.map((vendor, index) =>
                                        <MenuItem key={index} value={vendor.vendorId}>{vendor.vendorName} - <small> {vendor.vendorAddress.city}, {vendor.vendorAddress.state}</small></MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* {id == undefined ? 
                            : null} */}

                        <Grid item xs={12} sm={6} md={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                <Select
                                    disabled={accountOwner && !userPermissions.admin && !isSupperAdmin ? true : false}
                                    error={errors.roleId !== '' ? true : false}
                                    helperText={errors.roleId}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="role"
                                    value={role}
                                    label="Role"
                                    onChange={(e) => {
                                        setRole(e.target.value);
                                        getRolePermissions(e.target.value);
                                    }}
                                >
                                    {roles.map((r, index) =>
                                        <MenuItem key={index} value={r.roleId}>{r.roleName} </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>}

                    {id == undefined && userId == null ? <Grid container item spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                                error={errors.password !== '' ? true : false}
                                helperText={errors.password}
                                id="outlined-error"
                                name="password"
                                label="Password"
                                value={fields.password}
                                fullWidth
                                type={'password'}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                                error={errors.confirmPassword !== '' ? true : false}
                                id="outlined-error"
                                name="confirmPassword"
                                helperText={errors.confirmPassword}
                                label="Confirm Password"
                                value={fields.confirmPassword}
                                fullWidth
                                type={'password'}
                                onChange={handleInputChange}
                            />
                        </Grid>

                    </Grid> : null}

                    {(id != undefined || userId != null) && (userPermissions.admin || isSupperAdmin) && type !== 'admin' ? <Grid container item spacing={1}>

                        <Grid item xs={12} sm={12} md={12}>
                            <br />
                            <Typography>Permissions</Typography>
                            <br />
                            <TransferList
                                key={updated}
                                onChange={(right, left) => {
                                    handleTransferChange(right, left);
                                    setShowSave(true);
                                }}
                                leftData={left}
                                rightData={right}
                            />
                        </Grid>

                    </Grid> : null}


                    <LoaderButton
                        style={{
                            marginTop: 10,
                            marginLeft: 5
                        }}
                        block
                        type="submit"
                        size="lg"
                        variant="primary"
                        isLoading={isLoading}
                        disabled={showSave ? false : true}
                        onClick={() => {
                            handleSubmit();
                        }}
                    >
                        {id == undefined && userId == null ? 'Create User' : 'Update'}
                    </LoaderButton>
                    {/* <Form.Group controlId="file">
                            <Form.Label>Store Profile</Form.Label>
                            <Form.Control onChange={handleFileChange} type="file" />
                        </Form.Group> */}

                </Grid>
            </Box>
        </div>
    );
}
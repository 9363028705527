import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import { useHistory, useParams } from "react-router-dom";
import { Text } from '../../services/translator';
import { useAppContext } from "../../lib/contextLib";
import TableFilter from '../shared/Filter';
import { Colors } from '../../lib/colors';
import DirectProductRequest from "../dialogs/DirectProductRequest";
import ProductTransfer1 from "../dialogs/ProductTransfer1";
import { Divider } from '@mui/material';


export default function GutterlessList({ productsData, pageTitle, showCategory }) {
    const history = useHistory();
    const {
        vendors
    } = useAppContext();
    const [products, setProducts] = React.useState([]);

    React.useEffect(() => {
        setProducts(productsData);
    }, [productsData]);

    function getVendorName(vendorId) {
        let name = '';
        if (vendors && vendorId) {
            vendors
                .filter(item => item.vendorId == vendorId)
                .map(item => {
                    name = item.vendorName;
                });
        }
        return name;
    }
    return (<div>
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>

                <TableFilter
                    onChange={(data) => {
                        setProducts(data);
                    }}
                    type={'products'}
                    data={productsData}
                />
                <Typography
                    sx={{ flex: '1 1 100%', marginLeft: 2 }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    {pageTitle}
                </Typography>

                <List sx={{
                    width: '100%',
                    //maxWidth: 360,
                    bgcolor: 'background.paper',
                    position: 'relative',
                    overflow: 'auto',
                    height: 335,
                    '& ul': { padding: 0 },
                }}>

                    <Divider />
                    {products.map((row) => (
                        <div key={row.productId + row.skId}>
                            {row.isDirectTransfer === undefined || row.isDirectTransfer === false || row.isDirectTransfer === null ?
                                <ListItem
                                    disableGutters
                                    secondaryAction={
                                        <ProductTransfer1
                                            btColor={row.isReturnTransfer && row.transferStatus === 'declined' ? 'error' : 'primary'}
                                            btTitle={
                                                row.isReturnTransfer && row.transferStatus === 'declined' ? 'DECLINED' :
                                                    row.isReturnTransfer && row.transferStatus === 'requested' ? 'Manage' :
                                                        row.isReturnTransfer && row.transferStatus === 'confirmed' ? <Text>Receive</Text> :
                                                            <Text>confirm</Text>
                                            }
                                            showForm={row.isReturnTransfer && row.transferStatus === 'requested' ? true : false}
                                            status={
                                                row.isReturnTransfer && row.transferStatus === 'declined' ? 'needRemove' :
                                                    row.isReturnTransfer && row.transferStatus === 'requested' ? 'requested' :
                                                        row.isReturnTransfer && row.transferStatus === 'confirmed' ? 'needReceive' :
                                                            'needAcceptance'
                                            }
                                            transfer={row}
                                            id={row.productTransferId}
                                        />
                                    }
                                >
                                    <ListItemText sx={{
                                        marginLeft: 2
                                    }}>{row.transferStatus && row.transferStatus === 'accepted' ? <Text>accepted by</Text> : null}
                                        {getVendorName(row.vendorId)}  {' - '}
                                        {
                                            row.transferStatus === 'declined' ? <Chip label={<Text>declined</Text>} color="error" size="small" /> :
                                                row.shippingStatus === 'shipped' ? <Chip label={<Text>shipped</Text>} color="success" size="small" /> :
                                                    row.shippingStatus === '' || row.shippingStatus === undefined || row.shippingStatus === null ? <Chip label={<Text>pending</Text>} size="small" /> :
                                                        row.shippingStatus === 'processing' ? <Chip label={<Text>processing</Text>} color="primary" size="small" /> :
                                                            null
                                        }
                                    </ListItemText>

                                </ListItem>
                                :
                                <ListItem
                                    disableGutters
                                    secondaryAction={
                                        <DirectProductRequest
                                            btColor={row.transferStatus && row.transferStatus === 'confirmed' ? 'primary' : 'error'}
                                            btTitle={row.transferStatus && row.transferStatus === 'confirmed' ? 'MANAGE' : 'REQUESTED'}
                                            showForm={true}
                                            status={'requestedDirect'}
                                            transfer={row}
                                            id={row.productTransferId}
                                        />
                                    }
                                >
                                    <ListItemText sx={{
                                        marginLeft: 2
                                    }}>
                                        DIRECT REQUEST: {getVendorName(row.transferTo)}  {' - '}
                                        {
                                            row.transferStatus === 'declined' ? <Chip label={<Text>declined</Text>} color="error" size="small" /> :
                                                row.shippingStatus === 'shipped' ? <Chip label={<Text>shipped</Text>} color="success" size="small" /> :
                                                    row.shippingStatus === '' || row.shippingStatus === undefined || row.shippingStatus === null ? <Chip label={<Text>pending</Text>} size="small" /> :
                                                        row.shippingStatus === 'processing' ? <Chip label={<Text>processing</Text>} color="primary" size="small" /> : null
                                        }

                                    </ListItemText>

                                </ListItem>
                            }

                            <small style={{
                                marginLeft: 15,
                                //marginTop: -20
                            }}><b><Text>FROM</Text>:</b> {getVendorName(row.vendorId)} <b><Text>TO</Text>:</b> {getVendorName(row.transferTo)}</small>
                            <br />

                            <small style={{
                                marginLeft: 15,
                                //marginTop: -20
                            }}><b><Text>BL NUMBER</Text>:</b> {row.blNumber}</small>

                            <Divider />


                        </div>
                    ))}
                    {products && products.length <= 0 ?
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            There is no record.
                        </div> : <div style={{ height: 30 }}>

                        </div>
                    }

                </List>

            </Paper>
        </Box>

    </div>
    );
}

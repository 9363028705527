import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CommentIcon from '@mui/icons-material/Comment';
import IconButton from '@mui/material/IconButton';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useHistory, useParams } from "react-router-dom";
import SendIcon from '@mui/icons-material/Send';
import DetailsIcon from '@mui/icons-material/Details';
import { LinkContainer } from "react-router-bootstrap";
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import {
    priceFormat
} from "../../services/shared";
import { Text } from '../../services/translator';
import ProductCats from "../../containers/product_categories/ProductCats";
import { useAppContext } from "../../lib/contextLib";
import TableFilter from '../shared/Filter';
import { Colors } from '../../lib/colors';
import ProductTransfer from "../dialogs/ProductTransfer";
import ProductTransfer1 from "../dialogs/ProductTransfer1";
import { Divider } from '@mui/material';
var moment = require('moment');

export default function GutterlessList({ productsData, pageTitle, showCategory }) {
    const history = useHistory();
    const {
        userHasAuthenticated,
        store,
        actions,
        productCategories,
        vendors
    } = useAppContext();
    const [products, setProducts] = React.useState([]);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('productId');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    React.useEffect(() => {
        setProducts(productsData);
    }, [productsData]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = products.map((n) => n.productId);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    function getCatName(categoryId) {
        let name = '';
        if (productCategories && categoryId) {
            productCategories
                .filter(item => item.categoryId == categoryId)
                .map(item => {
                    //  alert(item.categoryName);
                    name = item.categoryName;
                });
        }
        return name;
    }

    function getVendorName(vendorId) {
        let name = '';
        if (vendors && vendorId) {
            vendors
                .filter(item => item.vendorId == vendorId)
                .map(item => {
                    //  alert(item.categoryName);
                    name = item.vendorName;
                });
        }
        return name;
    }

    return (<div>
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>

                <TableFilter
                    //onChange={onSearch}
                    onChange={(data) => {
                        setProducts(data);
                    }}
                    type={'products'}
                    data={productsData}
                />
                <Typography
                    sx={{ flex: '1 1 100%', marginLeft: 2 }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    {pageTitle}
                </Typography>

                <List sx={{
                    width: '100%',
                    //maxWidth: 360,
                    bgcolor: 'background.paper',
                    position: 'relative',
                    overflow: 'auto',
                    height: 335,
                    '& ul': { padding: 0 },
                }}>

                    <Divider />
                    {products.map((row, idx) => (//For transfering From vendor
                        <div key={row.productTransferId}>
                            <ListItem
                                disableGutters
                                secondaryAction={row.transferStatus !== 'declined' ?
                                    < ProductTransfer1
                                        btColor={'primary'}
                                        btTitle={row.isReturnTransfer && row.transferStatus === 'confirmed' ? 'Manage' : <Text>Accept/Decline</Text>}
                                        showForm={true}
                                        //status={'needAcceptance'}
                                        status={row.isReturnTransfer && row.transferStatus === 'confirmed' ? 'needReceive' : 'needAcceptance'}
                                        transfer={row}
                                        productId={row.productId}
                                        id={row.productTransferId}
                                        onSave={() => {

                                        }}
                                    /> :
                                    < ProductTransfer1
                                        btColor={'error'}
                                        btTitle={<Text>View</Text>}
                                        showForm={false}
                                        status={'needRemove'}
                                        transfer={row}
                                        productId={row.productId}
                                        id={row.productTransferId}
                                        onSave={() => {

                                        }}
                                    />

                                }
                            >
                                <ListItemText sx={{
                                    marginLeft: 2
                                }}><b><Text>TO</Text>:</b> {getVendorName(row.transferTo)} {' - '}

                                    {
                                        row.transferStatus === 'declined' ? <Chip label={<Text>declined</Text>} color="error" size="small" /> :
                                            row.transferStatus === 'accepted' ? <Chip label={<Text>accepted</Text>} color="primary" size="small" /> :
                                                row.shippingStatus === 'shipped' ? <Chip label={<Text>shipped</Text>} color="success" size="small" /> :
                                                    row.shippingStatus === '' || row.shippingStatus === undefined || row.shippingStatus === null ? <Chip label={<Text>pending</Text>} size="small" /> :
                                                        row.shippingStatus === 'processing' ? <Chip label={<Text>processing</Text>} color="primary" size="small" /> : null
                                    }
                                </ListItemText>

                            </ListItem>
                            <small style={{
                                marginLeft: 15,
                                //marginTop: -20
                            }}><b><Text>Requested Date</Text>:</b> {moment(row.transferDate).format('L')}</small>
                            <br />
                            <small style={{
                                marginLeft: 15,
                                //marginTop: -20
                            }}><b><Text>BL Number</Text>:</b> {row.blNumber || 'N/A'} </small>

                            <Divider />
                        </div>
                    ))}
                    {products && products.length <= 0 ?
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            There is no record.
                        </div> : <div style={{ height: 30 }}>

                        </div>
                    }
                </List>

            </Paper>
        </Box>
    </div>
    );
}

import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { API, Storage } from "aws-amplify";
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Grid';
import { onError } from "../../lib/errorLib";
import { useAppContext } from "../../lib/contextLib";
import { s3Upload, s3Remove } from "../../lib/awsLib";
import { Text } from '../../services/translator';
import {
    saveProduct,
    updateProduct,
    getProduct
} from "../../services/products";

const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('sm')]: {
        width: '100% !important', // Overrides inline-style
        height: 100,
    },
    '&:hover, &.Mui-focusVisible': {
        zIndex: 1,
        '& .MuiImageBackdrop-root': {
            opacity: 0.15,
        },
        '& .MuiImageMarked-root': {
            opacity: 0,
        },
        '& .MuiTypography-root': {
            border: '4px solid currentColor',
        },
    },
}));

const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
});

const Image = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({ theme }) => ({
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
}));

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};


const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginTop: 8,
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};


export default function MultiFilesUpload({ id, onUpdate, formInput }) {
    const { userHasAuthenticated, store, actions, userPermissions } = useAppContext();
    const [files, setFiles] = useState([]);
    const [images, setImages] = useState([]);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone(
        {
            accept: { 'image/*': [] },

            onDrop: acceptedFiles => {

                onUpLoadFiles(acceptedFiles);

                let newFiles = files;
                acceptedFiles.map(file => {
                    let newFile = Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    });
                    newFiles.push(newFile);
                }
                )
                
                setFiles(newFiles);
            }

        });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);


    const thumbs = files.map((file, index) => (

        <Grid item xs={4} sm={3} md={3} key={file.preview + index}>
            <ImageButton
                focusRipple
                key={index}
                style={{
                    marginTop: 15,
                    width: '100%',
                    height: 150
                }}
                onLoad={() => { URL.revokeObjectURL(file.preview) }}
                onClick={() => {
                    // history.push(`/products/${selectedPro.productId}`);
                }}

            >
                <ImageSrc style={{ backgroundImage: `url(${file.preview})` }} />
                <ImageBackdrop className="MuiImageBackdrop-root" />
                <Image>

                </Image>

            </ImageButton>
            <Button
                disabled={userPermissions.admin == true || userPermissions.products_write == true ? false : true}
                fullWidth
                color={'error'}
                variant="outlined"
                onClick={() => {
                    removeFile(file.preview, index);
                    forceUpdate();
                }}
                startIcon={<DeleteIcon />}>
                <Text>remove</Text>
            </Button>
        </Grid>

        // <div style={thumb} key={index}>
        //     <div style={thumbInner}>
        //         <img
        //             src={file.preview}
        //             style={img}
        //             // Revoke data uri after image is loaded
        //             onLoad={() => { URL.revokeObjectURL(file.preview) }}
        //         />

        //     </div>


        // </div>
    ));

    async function onUpLoadFiles(acceptedFiles) {
        try {
            let newImages = images;
            for (let i = 0; i < acceptedFiles.length; i++) {
                const image = acceptedFiles[i] ? await s3Upload(acceptedFiles[i]) : null;
                if (image != null) {
                    newImages.push(image);
                }
            }
            formInput.mainImage = newImages[0];

            let response = await updateProduct(id, { productImages: newImages, ...formInput });
            actions.onSetFeedback(true, 'Images was successfully updated.', 'success');

            onUpdate(newImages);

            setImages(newImages);
        } catch (e) {
            onError(e);
        }
    }

    const removeFile = async (link, index) => {
        try {
            let newImages = images;
            let newFiles = files;

            if (index !== -1) {
                newImages.splice(index, 1);
                newFiles.splice(index, 1);
                formInput.mainImage = newImages[0];

                let response = await updateProduct(id, { productImages: newImages, ...formInput });

                setImages(newImages);
                setFiles(newFiles);
                onUpdate(newImages);
                let removed = await s3Remove(newImages[index]);
            }

        } catch (e) {
            onError(e);
        }
    };

    useEffect(() => {

        async function onLoad() {
            try {
                let newProductImages = [];

                const product = await getProduct(id);

                // const {
                //     productImages
                // } = product;

                let productImages = product.productImages || [];

                productImages.forEach(element => {
                    newProductImages.push(element);
                });

                let newFiles = [...files];

                if (productImages) {

                    for (let i = 0; i < productImages.length; i++) {
                        // let url = await Storage.vault.get(productImages[i]);
                        let url = await Storage.vault.get(productImages[i], { level: 'public' });
                        
                        let obj = {
                            preview: '',
                            path: ''
                        };
                        obj.preview = url;
                        obj.path = productImages[i];
                        
                        newFiles.push(obj);
                    }

                }
                
                setFiles(newFiles);
                setImages(newProductImages);
                onUpdate(newProductImages);

            } catch (e) {
                onError(e);
            }
        }

        onLoad();
    }, [id]);

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);

    return (
        <div className="container">
            
            {userPermissions.admin == true || userPermissions.products_write == true ?
                <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <p style={{ marginTop: 15 }}>Drag 'n' drop some files here, or click to select files</p>
                </div>
                : null}


            <Grid container spacing={1}>
                {thumbs}
            </Grid>

            {/* {thumbs} */}
        </div>
    );
}

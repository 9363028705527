import React, { useState, useEffect, useContext } from "react";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import LoaderButton from "../LoaderButton";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';

import { useAppContext } from "../../lib/contextLib";
import { onError } from "../../lib/errorLib";
import { Text } from '../../services/translator';
import {
    saveProductTransfer,
    updateProductTransfer,
    removeProductTransfer,
    receiveProductDirectTransfer
} from "../../services/products_transfer";
import {
    getVendorName,
    getUserName,
    getCatName
} from "../../services/shared";

var moment = require('moment');

export default function DirectProductRequest({ transfer, status, showForm = true, id = 0, btTitle, btColor }) {

    const {
        actions,
        vendors,
        mainVendors,
        vendor,
        user,
        users,
        productCategories,
        companyId,
        allProducts,
        shippingStatus
    } = useAppContext();

    const [open, setOpen] = useState(false);
    const [showSave, setShowSave] = useState(false);
    const [vendorId, setVendorId] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [formInput, setFormInput] = useState({
        vendorId: '', // Transfer from
        transferTo: '',
        transferProcessor: '',
        blNumber: 'BL-' + Date.now(),
        transferDate: Date.now(),
        transferReason: '',
        transferStatus: 'requestedDirect',
        shippingStatus: '',
        requestedBy: '',
        acceptedBy: null,
        confirmedBy: null,
        acceptedDate: null,
        confirmedDate: null,
        transferItems: [],
        isDirectTransfer: true
    });

    useEffect(async () => {
        if (id === 0) {
            let newFormInput = {
                ...formInput,
                ['transferDate']: Date.now(),
                ['requestedBy']: user.userId,
                ['transferTo']: vendor.vendorId,
                ['transferStatus']: 'requestedDirect',
                ['isDirectTransfer']: true,

            };

            setFormInput(newFormInput);
        } else {
            setVendorId(transfer.vendorId);
            setFormInput({ ...transfer });
        }

    }, [transfer, user, vendor, status, id, allProducts]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = async () => {
        setOpen(false);
    };

    const handleSave = async () => {
        let newFormInput = { ...formInput };
        setIsLoading(true);
        setShowSave(false);
        if (id == 0) {
            await saveProductTransfer({ ...newFormInput });
            actions.onSetFeedback(true, 'Product transfer was successfully created.', 'success');
        } else {
            await updateProductTransfer(id, { ...newFormInput });
            actions.onSetFeedback(true, 'Product transfer was successfully updated.', 'success');
        }

        actions.onGetProductTransfers(vendor.vendorId, companyId);

        setShowSave(true);
        setIsLoading(false);
        setOpen(false);
    };


    const handleStatusUpdate = async (requestStaus) => {
        let newFormInput = { ...formInput };

        setIsLoading(true);
        setShowSave(false);
        let feedB = '';

        if (requestStaus === 'accept') {
            newFormInput.transferStatus = 'accepted';
            newFormInput.acceptedBy = user.userId;
            newFormInput.acceptedDate = Date.now();
            feedB = 'Product transfer was successfully accepted.'
        } else if (requestStaus === 'decline') {
            newFormInput.transferStatus = 'declined';
            newFormInput.acceptedBy = user.userId;
            newFormInput.acceptedDate = Date.now();
            feedB = 'Product transfer was successfully declined.'
        } else if (requestStaus === 'confirm') {
            newFormInput.transferStatus = 'confirmed';
            newFormInput.confirmedBy = user.userId;
            newFormInput.confirmedDate = Date.now();
            newFormInput.acceptedBy = user.userId;
            newFormInput.acceptedDate = Date.now();
            newFormInput.shippingStatus = 'processing'
            feedB = 'Product transfer was successfully confirmed.'
        }
        await updateProductTransfer(id, { ...newFormInput });
        setFormInput(newFormInput);
        actions.onSetFeedback(true, feedB, 'success');
        actions.onGetProductTransfers(vendor.vendorId, companyId);

        setShowSave(true);
        setIsLoading(false);
        setOpen(false);
    };


    const handleCancel = async () => {

        setIsLoading(true);
        setShowSave(false);

        await removeProductTransfer(id);
        actions.onSetFeedback(true, 'Product transfer was successfully cancelled.', 'success');
        actions.onGetProductTransfers(vendor.vendorId, companyId);

        setShowSave(true);
        setIsLoading(false);
        setOpen(false);
    };

    const handleReceive = async (data) => {

        let text = "Are you sure?";
        if (window.confirm(text) === false) {
            return;
        }
        setIsLoading(true);
        setShowSave(false);

        await receiveProductDirectTransfer(data, user.userId);
        actions.onSetFeedback(true, 'Product transfer was successfully received.', 'success');
        actions.onGetProductTransfers(vendor.vendorId, companyId);
        actions.onGetProducts(vendor.vendorId, companyId);
        setShowSave(true);
        setIsLoading(false);
        setOpen(false);
    };

    function handleChange(event) {

        let { name, value } = event.target;
        let newFormInput = {
            ...formInput,
            [name]: value
        };

        setFormInput(newFormInput);
        setShowSave(true);
    }

    function handleInputChange(event, data) {

        let { name, value } = event.target;
        let newProductTransfer = [...formInput.transferItems];
        const index = newProductTransfer.indexOf(data);
        let row = newProductTransfer[index];

        if (name == 'productQuantity' && +value > +data.availableQuantity) {
            return onError('Quantity not available. Available QTY: ' + data.availableQuantity);
        }

        let newRow = {
            ...row,
            [name]: value
        };

        newProductTransfer[index] = newRow;

        let newFormInput = {
            ...formInput,
            ['transferItems']: newProductTransfer
        };

        setFormInput(newFormInput);
        setShowSave(true);
    }


    function handleProductChange(data, obj) {
        let newProductTransfer = [...formInput.transferItems];

        const index = newProductTransfer.indexOf(data);

        let row = newProductTransfer[index];

        let newRow = {
            ...row,
            ['productId']: obj.productId,
            ['categoryId']: obj.categoryId,
            ['productQuantityType']: obj.productQuantityType,
            ['availableQuantity']: obj.productQuantity,
            ['productDescription']: obj.productDescription,
        };

        newProductTransfer[index] = newRow;

        let newFormInput = {
            ...formInput,
            ['transferItems']: newProductTransfer
        };

        setFormInput(newFormInput);
        setShowSave(true);
    }

    const handleVendorChange = async (event) => {
        let { name, value } = event.target;

        let newFormInput = {
            ...formInput,
            ['vendorId']: value,
            ['transferProcessor']: value,
            ['transferItems']: []
        };

        setFormInput(newFormInput);
        setShowSave(true);
        setVendorId(value);
    }

    const addRow = async () => {
        let newProductTransfer = [...formInput.transferItems];
        let data = {
            productId: '',
            productDispatchId: '',
            categoryId: '',
            productTransferId: 'unique' + Date.now(),
            blNumber: 'BL-' + Date.now(),
            productQuantity: '',
            availableQuantity: '',
            productQuantityType: '',
            productDescription: '',
            status: 'pending'
        };

        newProductTransfer.push(data);

        let newFormInput = {
            ...formInput,
            ['transferItems']: newProductTransfer
        };

        setFormInput(newFormInput);
    };

    const handleRemove = async (data) => {

        let newProductTransfer = [...formInput.transferItems];

        const index = newProductTransfer.indexOf(data);
        if (index > -1) {
            newProductTransfer.splice(index, 1);
        }

        let newFormInput = {
            ...formInput,
            ['transferItems']: newProductTransfer
        };

        setFormInput(newFormInput);

    };


    return (
        <div>{showForm === true ?

            <div >
                <Button style={{ marginLeft: "15px", marginRight: "15px", marginTop: "10px" }} variant="contained" onClick={handleClickOpen} color={btColor}>
                    {btTitle}
                </Button>

                <Dialog
                    fullWidth
                    maxWidth={'md'}
                    open={open}
                    onClose={handleClose}
                >
                    <DialogTitle>
                        Direct Product Request - {getVendorName(vendors, formInput.transferTo)}
                    </DialogTitle>
                    <DialogContent >

                        {formInput.transferTo === vendor.vendorId &&
                            <Typography gutterBottom variant="h4" component="div">
                                {id !== 0 &&
                                    <LoaderButton
                                        type="submit"
                                        size="sm"
                                        variant="danger"
                                        isLoading={isLoading}
                                        onClick={handleCancel}
                                    >
                                        <Text>Cancel Request</Text>
                                    </LoaderButton>
                                }
                            </Typography>
                        }

                        {status === 'requestedDirect' && transfer.transferStatus !== 'declined' &&
                            <Typography gutterBottom variant="h4" component="div">
                                {id !== 0 && <>

                                    {transfer.transferStatus !== 'confirmed' &&
                                        <LoaderButton
                                            style={{ marginRight: "15px" }}
                                            type="submit"
                                            size="sm"
                                            //variant="danger"
                                            isLoading={isLoading}
                                            onClick={() => {
                                                handleStatusUpdate('confirm');
                                            }}
                                        >
                                            CONFIRM REQUEST
                                        </LoaderButton>
                                    }

                                    <LoaderButton

                                        type="submit"
                                        size="sm"
                                        variant="danger"
                                        isLoading={isLoading}
                                        onClick={() => {
                                            handleStatusUpdate('decline');
                                        }}
                                    >
                                        <Text>DECLINE REQUEST</Text>
                                    </LoaderButton>
                                </>
                                }
                            </Typography>
                        }

                        <Grid item xs={12} sm={12} md={12} style={{ marginTop: 10 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Select Vendor</InputLabel>
                                <Select
                                    disabled={status == 'requestedDirect' ? true : false}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="transferTo"
                                    value={vendorId}
                                    label="Transfer To"
                                    onChange={handleVendorChange}
                                >
                                    {mainVendors.map((vendor, index) =>
                                        <MenuItem key={'fdkor443' + index} value={vendor.vendorId}>{vendor.vendorName} | {vendor.vendorAddress.city || ''}, {vendor.vendorAddress.state || ''}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>

                        {formInput.transferStatus === 'confirmed' && <Grid item xs={12} sm={12} md={12} style={{ marginTop: 10 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Shipping Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="shippingStatus"
                                    value={formInput.shippingStatus}
                                    label="Shipping Status"
                                    onChange={handleChange}
                                >
                                    {shippingStatus.map((shippingStatus, index) =>
                                        <MenuItem key={'shippingStatus' + index} value={shippingStatus.value}>{shippingStatus.name}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>}

                        <Grid container spacing={3} style={{ marginTop: 5 }}>

                            <Grid item xs={6} sm={6} md={6}>
                                <TextField
                                    autoFocus
                                    id="transferDate"
                                    name="transferDate"
                                    label={<Text>Transfer Date</Text>}
                                    type="date"
                                    disabled={status == 'requestedDirect' ? true : false}
                                    fullWidth
                                    defaultValue={formInput.transferDate}
                                    onChange={handleChange}
                                />

                            </Grid>

                            {(status == 'accepted' || status === 'requestedDirect') &&
                                <Grid item xs={6} sm={6} md={6}>
                                    <TextField
                                        id="blNumber"
                                        name="blNumber"
                                        label={<Text>BL Number</Text>}
                                        fullWidth
                                        //variant="standard"
                                        defaultValue={formInput.blNumber}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            }

                            {(status == 'accepted' || status == 'requestedDirect') &&
                                <Grid item xs={6} sm={6} md={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label"><Text>Approver</Text></InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="transferProcessor"
                                            value={formInput.transferProcessor}
                                            onChange={handleChange}
                                        >
                                            {vendors.map((vendor, index) =>
                                                vendor.isMainVendor === true && <MenuItem key={'o4444j334' + index} value={vendor.vendorId}>{vendor.vendorName}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            }

                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    disabled={status == 'requestedDirect' ? true : false}
                                    id="transferReason"
                                    name="transferReason"
                                    label={<Text>Transfer Reason</Text>}
                                    multiline
                                    maxRows={4}
                                    minRows={2}
                                    fullWidth
                                    defaultValue={formInput.transferReason}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Divider />
                                <Divider><b><Text>Products</Text></b></Divider>
                                <Divider />
                            </Grid>
                            {formInput.transferItems && formInput.transferItems.map((item, index) =>
                                <>
                                    <Grid item xs={12} sm={6} md={6} key={'ProductsL' + index}>

                                        <Autocomplete
                                            disabled={status === 'requestedDirect' ? true : false}
                                            freeSolo
                                            id="combo-box-demo"
                                            options={allProducts}
                                            defaultValue={item}
                                            getOptionLabel={(option) => option.productDescription || ''}
                                            onChange={(_event, obj) => {
                                                handleProductChange(item, obj);
                                            }}
                                            sx={{ width: 300 }}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                helperText={"QTY: " + (+item.availableQuantity - +item.productQuantity) + " " + item.productQuantityType}
                                                fullWidth
                                                label="Select product"
                                            />}
                                        />

                                    </Grid>

                                    <Grid item xs={12} sm={5} md={5}>
                                        <TextField
                                            id="productQuantity"
                                            name="productQuantity"
                                            label={<Text>Quantity</Text>}
                                            fullWidth
                                            defaultValue={item.productQuantity}
                                            onChange={(e) => {
                                                handleInputChange(e, item);
                                            }}
                                        />

                                    </Grid>

                                    <Grid item xs={12} sm={1} md={1}>
                                        <IconButton
                                            style={{
                                                marginTop: 10,
                                            }}
                                            color="error"
                                            aria-label="remove attribute"
                                            component="span"
                                            onClick={() => {
                                                handleRemove(item);
                                            }}
                                        >
                                            <DeleteForeverIcon />
                                        </IconButton>
                                    </Grid>
                                    <Divider />
                                </>
                            )}

                            <Grid item xs={12} sm={12} md={12}>
                                {status === 'requested' && <LoaderButton
                                    style={{
                                        marginTop: 10,
                                        marginBottom: 10,
                                    }}
                                    type="submit"
                                    size="sm"
                                    variant="primary"
                                    onClick={() => {
                                        addRow();
                                    }}
                                >
                                    {'NEW ROW'}
                                </LoaderButton>}
                            </Grid>

                        </Grid>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={handleClose} ><Text>Cancel</Text></Button>

                        <LoaderButton
                            type="submit"
                            size="sm"
                            variant="primary"
                            isLoading={isLoading}
                            onClick={handleSave}
                        >
                            <Text>Save</Text>
                        </LoaderButton>

                    </DialogActions>
                </Dialog>
            </div>
            :
            <div>
                <Button variant="outlined" onClick={handleClickOpen} color={btColor} style={{ marginRight: 15 }}>
                    {btTitle}
                </Button>
                <Dialog
                    fullWidth
                    maxWidth={'md'}
                    open={open}
                    onClose={handleClose}
                >
                    <DialogTitle>
                        {'Product Transfer'} {' | '}
                        {transfer.transferStatus === 'declined' ? <Chip label={<Text>declined</Text>} color="error" size="small" /> :
                            transfer.transferStatus === 'confirmed' ? <Chip label={<Text>confirmed</Text>} color="success" size="small" /> :
                                transfer.transferStatus === 'requested' || transfer.transferStatus === 'requestedDirect' ? <Chip label={<Text>requested</Text>} size="small" /> :
                                    transfer.transferStatus === 'accepted' ? <Chip label={<Text>accepted</Text>} color="primary" size="small" /> :
                                        null

                        }

                    </DialogTitle>

                    <Divider variant="middle" />
                    <DialogContent>
                        <Grid container spacing={3} >
                            <Box sx={{ width: '100%', minWidth: 460, bgcolor: 'background.paper' }}>
                                <Box sx={{ my: 3, mx: 2 }}>
                                    <Divider variant="middle" />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>BL Number</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {transfer.blNumber}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>Transferred From</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {getVendorName(vendors, transfer.vendorId)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>Transferred To</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {getVendorName(vendors, transfer.transferTo)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>Requested Date</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {moment(transfer.transferDate).format('L')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>Requested By</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">

                                                {getUserName(users, transfer.requestedBy)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" />

                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>Accepted Date</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {moment(transfer.acceptedDate).format('L')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>Accepted By</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {getUserName(users, transfer.acceptedBy)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" />
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography gutterBottom variant="p" component="div">
                                                <Text>Approver</Text>:
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography gutterBottom variant="p" component="div">
                                                {getVendorName(vendors, transfer.transferProcessor)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" />
                                    <Typography color="text.secondary" variant="body2">
                                        {transfer.transferReason}
                                    </Typography>
                                    <br />
                                    <br />
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Divider />
                                        <Divider><b><Text>Products</Text></b></Divider>
                                        <Divider />
                                    </Grid>

                                    {formInput.transferItems && formInput.transferItems.map((item, index) =>
                                        <Grid item xs={12} sm={12} md={12} key={'lkdcdk' + index}>

                                            <Grid container alignItems="center">
                                                <Grid item xs>
                                                    <Typography gutterBottom variant="p" component="div">
                                                        <b><Text>Product Description</Text>:</b> {item.productDescription} {" - " + getCatName(productCategories, item.categoryId)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography gutterBottom variant="p" component="div">
                                                        <b>QTY:</b> {item.productQuantity} {item.productQuantityType}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Divider variant="middle" />
                                        </Grid>
                                    )}

                                </Box>

                            </Box>
                        </Grid>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={handleClose} ><Text>Cancel</Text></Button>

                        {(transfer.transferTo === vendor.vendorId && transfer.transferStatus !== 'accepted' && transfer.transferStatus !== 'confirmed') &&
                            <LoaderButton
                                type="submit"
                                size="sm"
                                variant="danger"
                                isLoading={isLoading}
                                onClick={handleCancel}
                            >
                                <Text>confirm</Text>
                            </LoaderButton>
                        }
                        {(status == 'needReceive') &&
                            <LoaderButton
                                type="submit"
                                size="sm"
                                variant="primary"
                                isLoading={isLoading}
                                onClick={() => {
                                    handleReceive(transfer);
                                }}
                            >
                                {<Text>Receive</Text>}
                            </LoaderButton>
                        }

                    </DialogActions>
                </Dialog>
            </div>
        }
        </div>
    );
}

import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Grid from '@mui/material/Grid';
import { useHistory, useParams } from "react-router-dom";
import { useAppContext } from "../../lib/contextLib";
import ProductTransfer1 from "../dialogs/ProductTransfer1";
import { Colors } from '../../lib/colors';
import TableFilter from '../../components/shared/Filter';
import ProductDetailsDialog from "../../containers/products/dialogs/ProductDetailsDialog";
import {
  priceFormat,
  getVendorName,
  getCatName
} from "../../services/shared";
import { Text } from '../../services/translator';
import MainProductRequest from "../dialogs/DirectProductRequest";

var moment = require('moment'); // require


function Row(props) {
  const {
    isAuthenticated,
    userPermissions,
    vendors,
    productCategories
  } = useAppContext();
  const history = useHistory();
  const { row, index } = props;
  const [open, setOpen] = React.useState(index == 0 ? true : false);
  const [products, setProducts] = React.useState(row.products);


  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {/* <TableCell component="th" scope="row">
          {moment(row.createdDate).format('L')}
        </TableCell> */}
        <TableCell align="right"><b>{getVendorName(vendors, row.vendorId)}</b></TableCell>
        {/* <TableCell align="right">{row.fullName}</TableCell>
        <TableCell align="right">{priceFormat(row.grandTotal)}</TableCell>
        <TableCell align="right">{priceFormat(row.shippingPrice)}</TableCell>
        <TableCell align="right">{priceFormat(row.orderCredit)}</TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: Colors.blue50 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>

              <Grid
                container
                spacing={2}
              //style={{ marginTop: '-40px' }}
              >
                <Grid item xs={12} sm={6} md={10}>
                  <Typography variant="h6" gutterBottom component="div">
                    <Text>Vendor Products</Text>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={2} style={{
                  display: 'flex',
                  justifyContent: 'right',
                  alignItems: 'right',
                }}>

                  <TableFilter
                    onChange={(data) => {
                      setProducts(data);
                    }}
                    type={'productsDispatch'}
                    data={row.products}
                  />

                </Grid>
              </Grid>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell> <Text>Product Description</Text></TableCell>
                    <TableCell> <Text>Product Category</Text></TableCell>
                    <TableCell> <Text>Price</Text></TableCell>
                    <TableCell> <Text>Quantity</Text></TableCell>
                    <TableCell> <Text>Actions</Text></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.map((pro) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={pro.productDispatchId + pro.createdAt}
                    >

                      <TableCell
                      // component="th"
                      // id={labelId}
                      //scope="row"
                      // padding="none"
                      >
                        {pro.productDescription}
                      </TableCell>
                      <TableCell align="left" key={pro.categoryId}>{getCatName(productCategories, pro.categoryId)}</TableCell>
                      <TableCell align="left">{priceFormat(pro.productPrice)}</TableCell>
                      <TableCell align="left">{pro.quantity} {pro.productQuantityType}</TableCell>
                      <TableCell align="left" >
                        <div style={{ display: 'flex', justifyContent: 'left' }}>
                          <ProductDetailsDialog
                            quantity={pro.quantity}
                            id={pro.productId}
                            isVendor={false}
                          />

                          {/* {isVendor !== true &&
                            <Button
                              variant="outlined"
                              //endIcon={<SendIcon />}
                              endIcon={<SettingsApplicationsIcon />}
                              onClick={() => {
                                history.push(`/a_portal/products/${row.productId}`);
                              }}>
                              <Text>Edit</Text>
                            </Button>
                          } */}

                        </div>

                      </TableCell>
                    </TableRow>
                  ))}

                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

// Row.propTypes = {
//   row: PropTypes.shape({
//     vendorId: PropTypes.string.isRequired,
//     categoryId: PropTypes.string.isRequired,
//     productPrice: PropTypes.number,
//     createdDate: PropTypes.number.isRequired,
//     productQuantityType: PropTypes.string,
//     productId: PropTypes.string,
//   }).isRequired,
// };

export default function vendors({ showTransfer, pageTitle, dispatchesData }) {

  return (

    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell style={{ fontSize: 20 }}><b>{pageTitle}</b></TableCell>
            <TableCell align="right">
            <Grid item xs={12} sm={12} md={12} >

<Grid container alignItems="center">
    <Grid item xs>
        <ProductTransfer1
            btColor={'primary'}
            btTitle={<Text>REQUEST</Text>}
            status={'requested'}
        />
    </Grid>
    <Grid item>
        <MainProductRequest
            btColor={'primary'}
            btTitle={<Text>Direct Request</Text>}
            status={'requested'}
        />
    </Grid>
</Grid>
</Grid>
            </TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {dispatchesData.map((row, index) => (
            <Row key={index} row={row} index={index} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
import { API } from "aws-amplify";
import {
    saveDispatch,
    updateDispatch,
    getProductDispatch,
    productDispatchData,
    saveDispatchHistoryProduct,
    saveDispatchHistoryVendor
} from "./products_dispatches";

import {
    getProduct,
    updateProduct
} from "./products";
import {
    productReceiveData,
    saveProductReceived
} from "./products_received";

var moment = require('moment');

/**
* Function for getting all product Transfer for sending vendor
* @param {function} getVendorsTransferProducts
*/
export const getVendorsTransferProducts = async (id) => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/products_transfer/list/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting all product Transfer for requester
* @param {function} getAllProductsTransferRequester
*/
export const getMyTransferProducts = async (id, companyId) => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/products_transfer/requester/${id}/${companyId}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting all product Transfer for processor
* @param {function} getPendingTransferProducts
*/
export const getPendingTransferProducts = async (id, companyId) => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/products_transfer/processor/${id}/${companyId}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};


/**
* Function to remove product Transfer
* @param {function} removeProductTransfer
*/
export const removeProductTransfer = async (id) => {

    let response;

    try {

        response = await API.del("adminAPI", `/admin/products_transfer/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};


/**
* Function for getting a product Transfer
* @param {function} getProductTransfer
*/
export const getProductTransfer = async (id) => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/products_transfer/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};


/**
* Function for saving  a product Transfer
* @param {object} data  
* @param {function} saveProductTransfer
*/
export const saveProductTransfer = async (data) => {

    let response;

    try {

        response = await API.post("adminAPI", "/admin/products_transfer", {
            body: data
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};


/**
* Function for updating a product Transfer
* @param {function} updateProductTransfer
*/
export const updateProductTransfer = async (id, data) => {

    let response;

    try {

        response = await API.put("adminAPI", `/admin/products_transfer/${id}`, {
            body: data
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for receiving products Transfered from vendor to vendor
* @param {function} receiveProductTransfer
*/
export const receiveProductTransfer = async (productTransfer, userId = '') => {

    let response;
    try {
        //Loop throught All products in the transfer request.
        for (let i = 0; i < productTransfer.transferItems.length; i++) {
            let productDispatch = null;

            if (productTransfer.transferItems[i].productDispatchId !== undefined) {
                let productDispatchObject = await productDispatchData();

                //Get Product Dispatche for Transfering Vendor and reduce quantity out START.
                productDispatch = await getProductDispatch(productTransfer.transferItems[i].productDispatchId);

                productDispatch.quantity = +productDispatch.quantity - +productTransfer.transferItems[i].productQuantity;

                updateDispatch(productTransfer.transferItems[i].productDispatchId, { ...productDispatch });

                //Transfer from vendor history
                saveProductTransferHistory({
                    recordOwnerId: productTransfer.vendorId,
                    ...productTransfer,
                });

                //Get Product Dispatche for Transfering Vendor and reduce quantity out END.
                productDispatchObject.productId = productDispatch.productId;
                productDispatchObject.productName = productDispatch.productName;
                productDispatchObject.productDescription = productDispatch.productDescription;
                productDispatchObject.categoryId = productDispatch.categoryId;
                productDispatchObject.productPrice = productDispatch.productPrice;
                productDispatchObject.productQuantityType = productDispatch.productQuantityType;
                productDispatchObject.quantityAlert = productDispatch.quantityAlert;
                productDispatchObject.quantity = productTransfer.transferItems[i].productQuantity;
                productDispatchObject.vendorId = productTransfer.transferTo;//Vendor the transfer going to.
                productDispatchObject.transferFrom = productTransfer.vendorId;//Vendor the transfer comming from.
                productDispatchObject.transferTo = productTransfer.transferTo;//Vendor the transfer going to.
                productDispatchObject.blNumber = productTransfer.blNumber;
                productDispatchObject.dispatchStatus = 'received';

                //Save dispatch history for product
                saveDispatchHistoryProduct({ ...productDispatchObject });
                //Save dispatch history for vendor getting the product
                saveDispatchHistoryVendor({ ...productDispatchObject });

                //Get product for receiving vendor and update
                let disId = productTransfer.transferTo + productTransfer.transferItems[i].productId;
                try {
                    let disRecord = await getProductDispatch(disId);
                    disRecord.quantity = +disRecord.quantity + +productTransfer.transferItems[i].productQuantity;
                    updateDispatch(disRecord.productDispatchId, { ...disRecord });
                } catch (err) {
                    productDispatch.quantity = productTransfer.transferItems[i].productQuantity;
                    productDispatch.vendorId = productTransfer.transferTo;
                    //Save Dispatch for Vendor Transfer
                    saveDispatch({ ...productDispatch });
                }
            }
        }

        //Remove product transfer when done
        await removeProductTransfer(productTransfer.productTransferId);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};


/**
* Function for receiving products Transfered directly from the main depot to vendor
* @param {function} receiveProductDirectTransfer
*/
export const receiveProductDirectTransfer = async (productTransfer, userId = '') => {

    let response;
    try {
        //Loop throught All products in the transfer request.
        for (let i = 0; i < productTransfer.transferItems.length; i++) {
            let productDispatch = null;

            if (productTransfer.transferItems[i].productDispatchId !== undefined) {
                let productDispatchObject = await productDispatchData();

                //Get original product record for quantity update 
                productDispatch = await getProduct(productTransfer.transferItems[i].productId);

                productDispatch.productQuantity = +productDispatch.productQuantity - +productTransfer.transferItems[i].productQuantity;

                //update product from depot
                updateProduct(productTransfer.transferItems[i].productId, { ...productDispatch });

                // Create Dispatch Data for a new dispatch record.
                productDispatchObject.productId = productDispatch.productId;
                productDispatchObject.productName = productDispatch.productName;
                productDispatchObject.productDescription = productDispatch.productDescription;
                productDispatchObject.categoryId = productDispatch.categoryId;
                productDispatchObject.productPrice = productDispatch.productPrice;
                productDispatchObject.productQuantityType = productDispatch.productQuantityType;
                productDispatchObject.quantityAlert = productDispatch.quantityAlert;
                productDispatchObject.quantity = productTransfer.transferItems[i].productQuantity;
                productDispatchObject.vendorId = productTransfer.transferTo;//Vendor the transfer going to.
                productDispatchObject.transferFrom = productTransfer.vendorId;//Vendor the transfer comming from.
                productDispatchObject.transferTo = productTransfer.transferTo;//Vendor the transfer going to.
                productDispatchObject.blNumber = productTransfer.blNumber;
                productDispatchObject.dispatchStatus = 'received';

                //Save dispatch history for product
                saveDispatchHistoryProduct({ ...productDispatchObject });
                //Save dispatch history for vendor getting the product
                saveDispatchHistoryVendor({ ...productDispatchObject });

                //Get product for receiving vendor and update
                let disId = productTransfer.transferTo + productTransfer.transferItems[i].productId;
                try {
                    let disRecord = await getProductDispatch(disId);
                    disRecord.productPrice = productDispatch.productPrice;
                    disRecord.quantity = +disRecord.quantity + +productTransfer.transferItems[i].productQuantity;
                    updateDispatch(disRecord.productDispatchId, { ...disRecord });
                } catch (err) {
                    saveDispatch({ ...productDispatchObject });
                }
            }
        }

        //Remove product transfer when done
        await removeProductTransfer(productTransfer.productTransferId);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for receiving products Returned From vendor to Depot Transfer
* @param {function} receiveProductReturnedTransfer
*/
export const receiveProductReturnedTransfer = async (productTransfer, userId) => {
    let response;
    try {
        //Loop throught All products in the transfer request.
        for (let i = 0; i < productTransfer.transferItems.length; i++) {
            let productDispatch = null;

            if (productTransfer.transferItems[i].productDispatchId !== undefined) {

                //Get Product Dispatche for Transfering Vendor and reduce quantity out START.
                productDispatch = await getProductDispatch(productTransfer.transferItems[i].productDispatchId);

                productDispatch.quantity = +productDispatch.quantity - +productTransfer.transferItems[i].productQuantity;

                updateDispatch(productTransfer.transferItems[i].productDispatchId, { ...productDispatch });

                //Transfer from vendor history
                saveProductTransferHistory({
                    recordOwnerId: productTransfer.vendorId,
                    ...productTransfer,
                });

                //Get Product Dispatche for Transfering Vendor and reduce quantity out END.

                //Get original product record for quantity update 
                productDispatch = await getProduct(productTransfer.transferItems[i].productId);
                productDispatch.productQuantity = +productDispatch.productQuantity + +productTransfer.transferItems[i].productQuantity;

                //update product from depot
                updateProduct(productTransfer.transferItems[i].productId, { ...productDispatch });

                let productReceivedObject = await productReceiveData();

                productReceivedObject.productId = productDispatch.productId;
                productReceivedObject.productDescription = productDispatch.productDescription;
                productReceivedObject.receiveStatus = 'received';
                productReceivedObject.categoryId = productDispatch.categoryId;
                productReceivedObject.productOrigin = productTransfer.vendorId;
                productReceivedObject.packagingNumber = productTransfer.blNumber;
                productReceivedObject.receivedDate = new Date();
                productReceivedObject.receivedBy = userId;
                productReceivedObject.createdBy = productTransfer.requestedBy;
                productReceivedObject.productQuantity = productTransfer.transferItems[i].productQuantity;
                productReceivedObject.productQuantityType = productDispatch.productQuantityType;
                productReceivedObject.productUnitPrice = productDispatch.productPrice;
                productReceivedObject.isReturned = true;

                saveProductReceived(productReceivedObject);
            }
        }

        //Remove product transfer when done
        await removeProductTransfer(productTransfer.productTransferId);

    } catch (err) {
        console.log('Error', err);
        throw 'Error: receiveProductReturnedTransfer';
    }

    return response;
};

//************************* HISTORY *******************************/
/**
* Function for getting all product Transfer history for sending vendor
* @param {function} getVendorsTransferProductsHistory
*/
export const getVendorsTransferProductsHistory = async (id) => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/products_transfer/history/list/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting a product Transfer history
* @param {function} getProductTransferHistory
*/
export const getProductTransferHistory = async (id) => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/products_transfer/history/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for saving a product Transfer History
* @param {object} data  
* @param {function} saveProductTransferHistory
*/
export const saveProductTransferHistory = async (data) => {

    let response;

    try {

        response = await API.post("adminAPI", "/admin/products_transfer/history", {
            body: data
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for updating a product Transfer history
* @param {function} updateProductTransferHistory
*/
export const updateProductTransferHistory = async (id, data) => {

    let response;

    try {

        response = await API.put("adminAPI", `/admin/products_transfer/history/${id}`, {
            body: data
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function to remove product Transfer history
* @param {function} removeProductTransferHistory
*/
export const removeProductTransferHistory = async (id) => {

    let response;

    try {

        response = await API.del("adminAPI", `/admin/products_transfer/history/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};




import React, { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { API } from "aws-amplify";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import Grid from '@mui/material/Grid';
import { useAppContext } from "../lib/contextLib";
import { onError } from "../lib/errorLib";
import Statistics from "../components/shared/Statistics";
import MyTransferProductsList from "../components/lists/MyTransferProductsList";
import PendingProductsList from "../components/lists/PendingProductsList";
import VendorsTransferProductsList from "../components/lists/VendorsTransferProductsList";
import PendingTransferProductsList from "../components/lists/PendingTransferProductsList";
import PendingReceiveProductsList from "../components/lists/PendingReceiveProductsList";
import SalesChart from "../components/charts/SalesChart";
import DashboardVendorProducts from "../components/tables/DashboardVendorProducts";
import OrdersTable from "../components/tables/OrdersTable";
import AdminDashboard from "./admin_dash/Index";
import "./Home.css";
import { Text } from '../services/translator';
import { Colors } from '../lib/colors';
import { Divider } from "@mui/material";

export default function Home() {
    const {
        isAuthenticated,
        vendorOrderStatistics,
        actions,
        pendingProducts,
        myTransferProducts,
        vendorsTransferProducts,
        pendingTransferProducts,
        vendor,
        receiveProducts,
        userPermissions,
        vendorsAvailableProducts,
        orders,
        ordersTodayVendor,
        isSupperAdmin
    } = useAppContext();


    useEffect(() => {
        actions.onSetSelected('home');
        console.log('pendingTransferProducts', pendingTransferProducts);
    }, [pendingTransferProducts]);

    function renderLander() {
        return (
            <div className="lander">
                <h1>You are not logged In</h1>
            </div>
        );
    }

    function renderAdminDashboard() {
        return (
            <div className="lander">
                <AdminDashboard/>
            </div>
        );
    }


    function renderDashboard() {

        return (
            <div className="notes">
                {/* <h2 className="pb-3 mt-4 mb-3 border-bottom"><Text>General Dashboard</Text></h2> */}
                <Statistics statistics={vendorOrderStatistics} />
                <Divider />
                <br />
                <br />
                <Grid container item spacing={3}>
                    {vendor.isMainVendor !== true &&
                        <>

                            {(userPermissions.admin === true || userPermissions.receive_products === true) &&
                                <>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <DashboardVendorProducts
                                            key={'876767766897hg'}
                                            pageTitle={<Text>Product by vendor</Text>}
                                            dispatchesData={vendorsAvailableProducts}
                                            showTransfer={vendor.isMainVendor == true ? false : true}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>
                                        <PendingReceiveProductsList
                                            pageTitle={<Text>Product Receivable</Text>}
                                            showCategory={false}
                                            productsData={receiveProducts.pendingReceives || []}
                                        />
                                    </Grid>
                                </>
                            }
                            <Grid item xs={12} sm={12} md={userPermissions.admin == true || userPermissions.receive_products == true ? 6 : 12}>
                                <MyTransferProductsList
                                    pageTitle={<Text>My Product Transfers</Text>}
                                    showCategory={false}
                                    productsData={myTransferProducts}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <OrdersTable
                                    pageTitle={<Text>Today Orders</Text>}
                                    dash={true}
                                    ordersData={ordersTodayVendor}
                                />
                            </Grid>


                        </>
                    }

                    {vendor.isMainVendor === true &&
                        <>
                            <Grid item xs={12} sm={12} md={6}>
                                {/* Put graph here */}
                                {/* Vendors pending product dispatch */}
                                <PendingReceiveProductsList
                                    pageTitle={<Text>Product Receivable</Text>}
                                    showCategory={false}
                                    productsData={receiveProducts.pendingReceives || []}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                {/* Accepted transfers that need confirmation from Main vendor */}
                                <PendingTransferProductsList
                                    pageTitle={<Text>Pending Product Transfers</Text>} 
                                    showCategory={false}
                                    productsData={pendingTransferProducts}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                {/* Put graph here */}
                                {/* <SalesChart
                                    data={monthlySales}
                                    title={<Text>Sales by month</Text>}
                                    color={Colors.navBlue}
                                /> */}
                                <DashboardVendorProducts
                                    key={'979908978iukj7'}
                                    pageTitle={<Text>Product by vendor</Text>}
                                    dispatchesData={vendorsAvailableProducts}
                                    showTransfer={vendor.isMainVendor == true ? false : true}
                                />
                            </Grid>
                        </>

                    }

                </Grid>
            </div>
        );
    }
    return (
        <div className="Home">
            {isAuthenticated && !isSupperAdmin ? renderDashboard() : isAuthenticated && isSupperAdmin? renderAdminDashboard() : !isAuthenticated && renderLander()}
        </div>
    );
}
import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useHistory } from "react-router-dom";
import Chip from '@mui/material/Chip';
import { Text } from '../../services/translator';
import { useAppContext } from "../../lib/contextLib";
import TableFilter from '../shared/Filter';
import ProductTransfer1 from "../dialogs/ProductTransfer1";
import DirectProductRequest from "../dialogs/DirectProductRequest";
import { Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import MainProductRequest from "../dialogs/DirectProductRequest";
var moment = require('moment');

export default function GutterlessList({ productsData, pageTitle, showCategory }) {
    const history = useHistory();
    const {
        vendors
    } = useAppContext();
    const [products, setProducts] = React.useState([]);

    React.useEffect(() => {
        setProducts(productsData);
    }, [productsData]);

    function getVendorName(vendorId) {
        let name = '';
        if (vendors && vendorId) {
            vendors
                .filter(item => item.vendorId == vendorId)
                .map(item => {
                    //  alert(item.categoryName);
                    name = item.vendorName;
                });
        }
        return name;
    }

    return (<div>
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableFilter
                    //onChange={onSearch}
                    onChange={(data) => {
                        setProducts(data);
                    }}
                    type={'products'}
                    data={productsData}
                />

                <Grid item xs={12} sm={12} md={12} >

                    <Grid container alignItems="center">
                        <Grid item xs>
                            <ProductTransfer1
                                btColor={'primary'}
                                btTitle={<Text>REQUEST</Text>}
                                status={'requested'}
                            />
                        </Grid>
                        <Grid item>
                            <MainProductRequest
                                btColor={'primary'}
                                btTitle={<Text>Direct Request</Text>}
                                status={'requested'}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                
                <Typography
                    sx={{ flex: '1 1 100%', marginLeft: 2 }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    {pageTitle}
                </Typography>

                <List sx={{
                    width: '100%',
                    //maxWidth: 360,
                    bgcolor: 'background.paper',
                    position: 'relative',
                    overflow: 'auto',
                    height: 315,
                    '& ul': { padding: 0 },
                }}>

                    <Divider />
                    {products.map((row) => (
                        <div key={"directTransferId" + row.skId}>
                            {row.isDirectTransfer === undefined || row.isDirectTransfer === false || row.isDirectTransfer === null ? <ListItem
                                disableGutters
                                secondaryAction={
                                    (row.transferStatus === 'accepted' || row.transferStatus === 'declined') ?
                                        <ProductTransfer1
                                            btColor={'primary'}
                                            btTitle={<Text>View</Text>}
                                            showForm={false}
                                            status={'requested'}
                                            transfer={row}
                                            id={row.productTransferId}
                                        /> : (row.transferStatus !== 'declined' && row.transferStatus != 'confirmed') ?
                                            <ProductTransfer1
                                                btColor={'warning'}
                                                btTitle={<Text>Edit</Text>}
                                                showForm={true}
                                                status={'requested'}
                                                transfer={row}
                                                id={row.productTransferId}
                                            /> : row.transferStatus === 'confirmed' ? <ProductTransfer1
                                                btColor={'success'}
                                                btTitle={<Text>Receive</Text>}
                                                showForm={false}
                                                status={'needReceive'}
                                                transfer={row}
                                                id={row.productTransferId}
                                            /> : row.transferStatus === 'declined' ? <ProductTransfer1
                                                btColor={'error'}
                                                btTitle={<Text>remove</Text>}
                                                showForm={false}
                                                status={'needRemove'}
                                                transfer={row}
                                                id={row.productTransferId}
                                            /> : null

                                }
                            >

                                <ListItemText sx={{
                                    marginLeft: 2
                                }}><b><Text>FROM</Text>:</b> {getVendorName(row.vendorId)} {' - '}

                                    {
                                        row.shippingStatus === 'shipped' ? <Chip label={<Text>shipped</Text>} color="success" size="small" /> :
                                            row.shippingStatus === '' || row.shippingStatus === undefined || row.shippingStatus === null ? <Chip label={<Text>pending</Text>} size="small" /> :
                                                row.shippingStatus === 'processing' ? <Chip label={<Text>processing</Text>} color="primary" size="small" /> : null
                                    }

                                </ListItemText>

                            </ListItem> :
                                <ListItem
                                    disableGutters
                                    secondaryAction={
                                        row.transferStatus === 'accepted' || row.transferStatus === 'requestedDirect' ?
                                            <DirectProductRequest
                                                btColor={'warning'}
                                                btTitle={<Text>Edit</Text>}
                                                showForm={true}
                                                status={'requested'}
                                                transfer={row}
                                                id={row.productTransferId}
                                            /> : row.transferStatus === 'declined' ? <DirectProductRequest
                                                btColor={'error'}
                                                btTitle={<Text>remove</Text>}
                                                showForm={false}
                                                status={'needRemove'}
                                                transfer={row}
                                                id={row.productTransferId}
                                            /> : <DirectProductRequest
                                                btColor={'success'}
                                                btTitle={<Text>Receive</Text>}
                                                showForm={false}
                                                status={'needReceive'}
                                                transfer={row}
                                                id={row.productTransferId}
                                            />
                                    }
                                >

                                    <ListItemText sx={{
                                        marginLeft: 2
                                    }}><b><Text>FROM</Text>:</b> {getVendorName(row.vendorId)} {' - '}

                                        {
                                            row.transferStatus === 'declined' ? <Chip label={'DECLINED'} color="error" size="small" />
                                                : row.shippingStatus === 'shipped' ? <Chip label={'SHIPPED'} color="success" size="small" /> :
                                                    row.shippingStatus === '' || row.shippingStatus === undefined || row.shippingStatus === null ? <Chip label={'PENDING'} size="small" /> :
                                                        row.shippingStatus === 'processing' ? <Chip label={'PROCESSING'} color="primary" size="small" /> :
                                                            null

                                        }
                                    </ListItemText>

                                </ListItem>}


                            <small style={{
                                marginLeft: 15,
                                //marginTop: -20
                            }}><b><Text>Requested Date</Text>:</b> {moment(row.transferDate).format('L')}</small>
                            <br />
                            <small style={{
                                marginLeft: 15,
                                //marginTop: -20
                            }}><b><Text>BL Number</Text>:</b> {row.blNumber || 'N/A'} </small>

                            <Divider />
                        </div>
                    ))}
                    {products && products.length <= 0 ?
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            There is no record.
                        </div> : <div style={{ height: 30 }}>

                        </div>
                    }

                </List>


            </Paper>
        </Box>
    </div>
    );
}

import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export default function BasicSelect({ lg, onChange, rows, title}) {
    const [value, setValue] = React.useState('');
    const [data, setData] = React.useState([]);

    const handleChange = (event: SelectChangeEvent) => {
        setValue(event.target.value as string);
        onChange(event.target.value);
    };

    React.useEffect(() => {
        setValue(lg as string);
        setData(rows as (string | number)[]);
    }, [lg]);

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth sx={{ marginTop: 1, backgroundColor: 'white', borderRadius: 2, borderColor: 'white' }}>
                <InputLabel id="demo-simple-select-label">{title}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    label={title}
                    onChange={handleChange}
                >
                    {data.map((l, index) =>
                        <MenuItem key={index + l.name} value={l.value}>{l.name}</MenuItem>
                    )}
                </Select>
            </FormControl>
        </Box>
    );
}
import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useHistory, useParams } from "react-router-dom";
import { Auth, API } from "aws-amplify";
//import LoaderButton from "../../components/LoaderButton";
import { useAppContext } from "../../lib/contextLib";
import { useFormFields } from "../../lib/hooksLib";

import validator from 'validator';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import InputMask from "react-input-mask";

import TransferList from "../../components/forms/TransferList";
import { s3Upload } from "../../lib/awsLib";
import LoaderButton from "../../components/LoaderButton";
import { onError } from "../../lib/errorLib";
import {
    saveUser,
    getUser,
    updateUser
} from "../../services/users";
import {
    getUserRole
} from "../../services/user_roles";
import { getAllVendors } from "../../services/vendors";
import { getAllUserRoles } from "../../services/user_roles";

import "./Signup.css";

const styles = {
    whiteBox: {
        padding: 20,
        backgroundColor: '#FFFFFF',
        borderRadius: 5
    }
};

export default function Signup() {
    const [fields, handleFieldChange] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        phoneNumber: "",
        userId: "",
        email: "",
        roleId: "",
        password: "",
        userPermissions: "",
        confirmPassword: "",
        // confirmationCode: "",
    });

    const { id } = useParams();
    const [updated, setUpdated] = useState(new Date());
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);
    const [showSave, setShowSave] = useState(false);
    const [accountOwner, setAccountOwner] = useState(false);
    const [active, setActive] = useState(true);
    const [vendorId, setVendor] = useState('');
    const [role, setRole] = useState('');
    const history = useHistory();
    const {
        userHasAuthenticated,
        store,
        actions,
        roles,
        vendors,
        permissions,
        vendor,
        user,
        userPermissions,
        companyId
    } = useAppContext();

    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        userId: "",
        vendorId: "",
        roleId: "",
        email: "",
        password: "",
        confirmPassword: "",
    });

    function updateErrors(name) {

        let newErrors = {
            ...errors,
            [name]: ''
        };

        setErrors(newErrors);

    }

    function validateForm() {
        let newErrors = { ...errors };
        let isValid = 1;

        if (vendorId == '') {
            newErrors.vendorId = 'Vendor is required.';
        } else {
            newErrors.vendorId = '';
        }

        if (fields.firstName == '') {
            newErrors.firstName = 'First name is required.';
        } else {
            newErrors.firstName = '';
        }

        if (fields.lastName == '') {
            newErrors.lastName = 'Last name is required.';
        } else {
            newErrors.lastName = '';
        }

        if (fields.phoneNumber == '') {
            newErrors.phoneNumber = 'Phone number is required.';
        } else {
            newErrors.phoneNumber = '';
        }

        if (fields.email.length < 0) {
            newErrors.email = 'Email address is required.';
        } else {
            if (validator.isEmail(fields.email)) {
                newErrors.email = '';
            } else {
                newErrors.email = 'Please Enter an valid Email Address.';
            }
        }

        setErrors(newErrors);

        if (
            newErrors.firstName.length > 0 ||
            newErrors.lastName.length > 0 ||
            newErrors.phoneNumber.length > 0 ||
            newErrors.email.length > 0 ||
            newErrors.vendorId.length > 0 
        ) {
            isValid = 0;
        }

        return isValid;
    }

    useEffect(() => {
        async function onLoad() {
            try {

                let vendors = await getAllVendors();
                let response = await getAllUserRoles();

            } catch (e) {
                onError(e);
            }
        }
        onLoad();
    }, []);


    function handleInputChange(event) {

        let { name, value } = event.target;

        let newFormInput = {
            ...fields,
            [name]: value
        };

        handleFieldChange({ ...newFormInput });
        updateErrors(name);
        setShowSave(true);
    }

    async function handleSubmit() {

        let isValid = validateForm();

        try {
            if (isValid) {

                setShowSave(false);
                const newUser = await Auth.signUp({
                    username: fields.email,
                    password: fields.password
                });

                let newCreatedUser = await createUser(newUser.userSub);
                actions.onSetIsLoading(false);

                actions.onSetFeedback(true, 'User was successfully created.', 'success');
                history.push("/a_portal/users/" + newCreatedUser.userId);

                //actions.onGetUsers();
            }


        } catch (e) {
            actions.onSetIsLoading(false);
            onError(e);

        }
    }

    async function createUser(userId) {
        try {
            const res = await saveUser({ ...fields, companyId, userId, active, vendorId, roleId: role });
            return res;
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    }

    async function handleTransferChange(rightData, leftData) {

        let newFormInput = {
            ...fields,
            ['userPermissions']: rightData
        };
        setLeft(leftData);
        setRight(rightData);
        handleFieldChange({ ...newFormInput });
    }


    useEffect(async () => {
        setUpdated(Date.now());
    }, [left, right]);

    async function getUserPermission(rightData) {
        let newLeft = [];

        try {

            permissions.forEach(permission => {
                if (permission.permissionValue) {

                    let per = rightData.filter((item) => item.permissionValue == permission.permissionValue);

                    if (per.length <= 0) {
                        newLeft.push(permission);
                    }
                }

            });


            setLeft(newLeft);
            setTimeout(() => {
                setShowSave(false);
            }, 1000);

        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        actions.onSetSelected('users');
    }, []);

    async function getRolePermissions(roleID) {
        let role = await getUserRole(roleID);
        const newUserPermissions = role.userPermissions || [];
        const leftData = [];
        const rightData = [];

        let newFormInput = {
            ...fields,
            ['userPermissions']: role.userPermissions || []
        };

        if(permissions.length > 0 ){
            permissions.forEach(permission => {

                if (permission.permissionValue) {
    
                    let per = newUserPermissions.filter((item) => item.permissionValue == permission.permissionValue);
    
                    if (per.length <= 0) {
                        leftData.push(permission);
                    } else {
                        rightData.push(permission);
                    }
    
                }
    
            });
    
            setRight(rightData);
            setLeft(leftData);
            setShowSave(true);
            handleFieldChange({ ...newFormInput });
        }
        
    }


    async function updateUserEmail(email) {
        try {
            const currentUser = await Auth.currentAuthenticatedUser();
            const result = await Auth.updateUserAttributes(currentUser, {
                email: email
            });
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="Signup"
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                //maxWidth: 500
            }}
        >

            <Box
                sx={{
                    // flexGrow: 1,
                    // display: 'flex',
                    // alignItems: 'center',
                    // justifyContent: 'center',
                    maxWidth: 600
                }}
                style={styles.whiteBox} >
                <h2 className="pb-3 mb-3 border-bottom"> {id == undefined ? 'User Create Form' : 'User Update Form'}</h2>

                <Grid container spacing={1}>

                    <Grid container item spacing={3}>

                        <Grid item xs={12} sm={6} md={6}>

                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography>Active</Typography>
                                <Switch
                                    checked={active}
                                    onChange={(e) => {
                                        setActive(e.target.checked);
                                        setShowSave(true);
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </Stack>

                        </Grid>

                    </Grid>

                    <Grid container item spacing={3}>

                        <Grid item xs={12} sm={12} md={12}>

                            <TextField
                                error={errors.firstName !== '' ? true : false}
                                id="outlined-error"
                                name="firstName"
                                helperText={errors.firstName}
                                label="First Name"
                                value={fields.firstName}
                                onChange={handleInputChange}
                                fullWidth
                            />

                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>

                            <TextField
                                error={errors.lastName !== '' ? true : false}
                                id="outlined-error"
                                name="lastName"
                                helperText={errors.lastName}
                                label="Last Name"
                                value={fields.lastName}
                                onChange={handleInputChange}
                                fullWidth
                            />

                        </Grid>

                    </Grid>

                    <Grid container item spacing={3}>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                error={errors.email !== '' ? true : false}
                                helperText={errors.email}
                                id="outlined-error"
                                name="email"
                                label="Email"
                                value={fields.email}
                                fullWidth
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <InputMask
                                mask="99 999 99 99"
                                value={fields.phoneNumber}
                                disabled={false}
                                maskChar=" "
                                onChange={handleInputChange}
                            >
                                {() =>
                                    <TextField
                                        error={errors.phoneNumber !== '' ? true : false}
                                        id="outlined-error"
                                        name="phoneNumber"
                                        helperText={errors.phoneNumber}
                                        label="Phone Number"
                                        fullWidth
                                    />}
                            </InputMask>
                            {/* 
                            <TextField
                                error={errors.phoneNumber !== '' ? true : false}
                                id="outlined-error"
                                name="phoneNumber"
                                helperText={errors.phoneNumber}
                                label="Phone Number"
                                fullWidth
                            /> */}
                        </Grid>

                    </Grid>

                    <Grid container item spacing={3}>
                        <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Vendor</InputLabel>
                                <Select
                                    error={errors.vendorId !== '' ? true : false}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="vendorId"
                                    value={vendorId}
                                    label="Vendor"
                                    onChange={(e) => {
                                        //handleInputChange(e);
                                        setVendor(e.target.value);
                                    }}
                                >
                                    {vendors.map((vendor, index) =>
                                        <MenuItem key={index} value={vendor.vendorId}>{vendor.vendorName} - <small>{vendor.vendorAddress.city}, {vendor.vendorAddress.state}</small></MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* {id == undefined ? 
                            : null} */}

                        <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                <Select
                                    error={errors.roleId !== '' ? true : false}
                                    helperText={errors.roleId}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="role"
                                    value={role}
                                    label="Role"
                                    onChange={(e) => {
                                        setRole(e.target.value);
                                        getRolePermissions(e.target.value);
                                    }}
                                >
                                    {roles.map((r, index) =>
                                        <MenuItem key={index} value={r.roleId}>{r.roleName} </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    {id == undefined ? <Grid container item spacing={3}>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                error={errors.password !== '' ? true : false}
                                helperText={errors.password}
                                id="outlined-error"
                                name="password"
                                label="Password"
                                value={fields.password}
                                fullWidth
                                type={'password'}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                error={errors.confirmPassword !== '' ? true : false}
                                id="outlined-error"
                                name="confirmPassword"
                                helperText={errors.confirmPassword}
                                label="Confirm Password"
                                value={fields.confirmPassword}
                                fullWidth
                                type={'password'}
                                onChange={handleInputChange}
                            />
                        </Grid>

                    </Grid> : null}
                   
                    <Grid container item spacing={1}>

                        <Grid item xs={12} sm={12} md={12}>
                            <br />
                            <Typography>Permissions</Typography>
                            <br />
                            <TransferList
                                key={updated}
                                onChange={(right, left) => {
                                    handleTransferChange(right, left);
                                    setShowSave(true);
                                }}
                                leftData={left}
                                rightData={right}
                            />
                        </Grid>

                    </Grid> 


                    <LoaderButton
                        style={{
                            marginTop: 10,
                            marginLeft: 5
                        }}
                        block
                        type="submit"
                        size="lg"
                        variant="primary"
                        isLoading={isLoading}
                        disabled={showSave ? false : true}
                        onClick={() => {
                            handleSubmit();
                        }}
                    >
                        {id == undefined ? 'Create User' : 'Update'}
                    </LoaderButton>
                    {/* <Form.Group controlId="file">
                            <Form.Label>Store Profile</Form.Label>
                            <Form.Control onChange={handleFileChange} type="file" />
                        </Form.Group> */}

                </Grid>
            </Box>
        </div>
    );
}
import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useHistory, useParams } from "react-router-dom";
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import {
    getCatName
} from "../../services/shared";

import {
    getProduct,
    _updateDispatch
} from "../../services/products";
import {
    removeApproval
} from "../../services/approvals";
import { Text } from '../../services/translator';
import { useAppContext } from "../../lib/contextLib";
import TableFilter from '../shared/Filter';
import { Divider } from '@mui/material';
var moment = require('moment');

export default function GutterlessList({ productsData, pageTitle }) {
    const history = useHistory();
    const {
        actions,
    } = useAppContext();
    const [products, setProducts] = React.useState([]);

    React.useEffect(() => {
        setProducts(productsData);
    }, [productsData]);


    const receiveProduct = async (data) => {
        try {
            if (window.confirm("Confirm product receive!") != true) {
                return;
            }

            actions.onSetIsLoading(true);
            const product = await getProduct(data.approvalData.productId);
            const id = data.approvalData.productId;
            let newProductDispatch = [...product.productDispatch];

            if (newProductDispatch.length > 0) {

                //Find dispatches
                newProductDispatch.filter(item => item.productDispatchId == data.approvalData.productDispatchId)
                    .map(item => {
                        const index = newProductDispatch.indexOf(item);
                        newProductDispatch[index].status = 'Received';
                    });

                product.productDispatch = newProductDispatch;

                _updateDispatch(product.productDispatch, product, id, true).then((data) => {
                    actions.onSetFeedback(true, 'Product was successfully received.', 'success');
                });

            } else {
                await removeApproval(data.approvalId);
            }

        } catch (err) {
            actions.onSetFeedback(true, err, 'error');
        }

    };

    return (<div>
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>

                <TableFilter
                    //onChange={onSearch}
                    onChange={(data) => {
                        setProducts(data);
                    }}
                    type={'pendingProducts'}
                    data={productsData}
                />
                <Typography
                    sx={{ flex: '1 1 100%', marginLeft: 2 }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    {pageTitle}
                </Typography>

                <List sx={{
                    width: '100%',
                    //maxWidth: 360,
                    bgcolor: 'background.paper',
                    position: 'relative',
                    overflow: 'auto',
                    height: 335,
                    '& ul': { padding: 0 },
                }}>

                    <Divider />
                    {products.map((row) => (
                        <div key={row.approvalData.dispatchDate}>
                            <ListItem
                                disableGutters
                                secondaryAction={
                                    <Button variant="contained" endIcon={<ConfirmationNumberIcon />}
                                        onClick={() => {
                                            receiveProduct(row);
                                        }}
                                        style={{ marginRight: 15 }}
                                    >
                                        <Text>Receive</Text>
                                    </Button>

                                }
                            >
                                <ListItemText sx={{
                                    marginLeft: 2
                                }}><Chip label={row.approvalData.dispatchStatus} color="success" size="large" /></ListItemText>

                            </ListItem>
                            <small style={{
                                marginLeft: 15,
                                //marginTop: -20
                            }}><b><Text>Product Description</Text>:</b> {row.approvalData.productDescription} ({getCatName(row.approvalData.categoryId) || 'N/A'}) <b><Text>Created Date</Text>:</b> {moment(row.approvalData.dispatchDate).format('L')}</small>
                            <br />
                            
                            <small style={{
                                marginLeft: 15,
                                //marginTop: -20
                            }}><b><Text>QUANTITY</Text>:</b> {row.approvalData.quantity} {row.approvalData.productQuantityType}{'(s)'} <b><Text>BL NUMBER</Text>:</b> {row.approvalData.blNumber}</small>

                            <Divider />

                        </div>
                    ))}
                    {products && products.length <= 0 ?
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            There is no record.
                        </div> : <div style={{ height: 30 }}>

                        </div>
                    }

                </List>

            </Paper>
        </Box>

    </div>
    );
}

import React, { useRef, useState, useEffect, useCallback } from "react";
import Form from "react-bootstrap/Form";
import ActionAreaCard from "../../../components/cards/ActionAreaCard";
import { useHistory, useParams } from "react-router-dom";
import validator from 'validator';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { API, Storage } from "aws-amplify";
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import LoaderButton from "../../../components/LoaderButton";
import FeedBack from "../../../components/shared/FeedBack";
import { s3Upload } from "../../../lib/awsLib";
import { onError } from "../../../lib/errorLib";
import config from "../../../config";
import { useAppContext } from "../../../lib/contextLib";
import { areacodes } from "../../../services/country_phone_areacodes";
import {
    saveCompany,
    updateCompany
} from "../../../services/companies";

const styles = {
    whiteBox: {
        padding: 20,
        backgroundColor: '#FFFFFF',
        borderRadius: 5
    }
};

export default function CompanyForm({ company = null, id = null }) {
    const file = useRef(null);
    const history = useHistory();
    const [showSave, setShowSave] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {
        userHasAuthenticated,
        store,
        actions,
        isSupperAdmin,
        inventoryCostChangeMethods,
        states
    } = useAppContext();
    const [choices, setChoices] = useState([
        { value: true, name: 'Yes' },
        { value: false, name: 'No' },
    ]);
    const [orderConfirmationCopyTo, setOrderConfirmationCopyTo] = useState([]);
    const [formInput, setFormInput] = useState({
        storeName: "",
        domainName: "",
        companyLogo: "",
        siteLanguage: "fr",
        currencyCode: "CFA",
        currencySymbol: "CFA",
        productsLimit: 10,
        orderConfirmationCopyTo: [],
        fromEmail: "",
        currentYear: "",
        billingCompany: true,//Free vs Paid
        phoneCountryCode: "221",
        companyPhone: "",
        companyEmail: "",
        companyTaxID: "",
        companyAddress: "",
        companyNote: "",
        inventoryCostChangeMethod: "AVG",
        hasCustomWebSite: false, //Has custom platform
        platformVendor: true, //Does vendor sale on our platfom
        companyCategory: "",
        showProductPrices: true,
        websiteType: "show_room", //show room or ecommerce
        active: true
    });
    const [companyAddress, setCompanyAddress] = useState({
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: 'Dakar',
        zipCode: '',
        country: 'SN'
    });

    const [errors, setErrors] = useState({
        orderConfirmationCopyTo: '',
        storeName: '',
        fromEmail: '',
        currentYear: '',
        phoneCountryCode: '',
        companyPhone: '',
        companyEmail: '',
        addressLine1: '',
        city: '',
        state: ''
    });

    useEffect(() => {

        async function onLoad() {
            try {
                if (id !== null) {
                    setFormInput({ ...company });
                    setOrderConfirmationCopyTo(company.orderConfirmationCopyTo || []);
                    setCompanyAddress(company.companyAddress);
                }
            } catch (e) {
                // onError(e);
            }
        }

        onLoad();

    }, [company, id]);

    function updateErrors(name) {

        let newErrors = {
            ...errors,
            [name]: ''
        };

        setErrors(newErrors);
    }

    function validateForm() {
        let newErrors = { ...errors };
        let isValid = 1;

        if (formInput.storeName == '') {
            newErrors.storeName = 'Company nasme is required.';
        } else {
            newErrors.storeName = '';
        }

        if (formInput.fromEmail == '') {
            newErrors.fromEmail = 'From email is required.';
        } else {
            newErrors.fromEmail = '';
        }

        if (formInput.currentYear == '') {
            newErrors.currentYear = 'Business year is required.';
        } else {
            newErrors.currentYear = '';
        }

        if (formInput.companyPhone == '') {
            newErrors.companyPhone = 'Company phone is required.';
        } else {
            newErrors.companyPhone = '';
        }

        if (formInput.companyEmail.length < 0) {
            newErrors.companyEmail = 'Email address is required.';
        } else {
            if (validator.isEmail(formInput.companyEmail)) {
                newErrors.companyEmail = '';
            } else {
                newErrors.companyEmail = 'Please Enter an valid Email Address.';
            }
        }

        if (companyAddress.addressLine1 == '') {
            newErrors.addressLine1 = 'Address line one is required.';
        } else {
            newErrors.addressLine1 = '';
        }

        if (companyAddress.city == '') {
            newErrors.city = 'City is required.';
        } else {
            newErrors.city = '';
        }

        setErrors(newErrors);

        if (
            newErrors.storeName.length > 0 ||
            newErrors.fromEmail.length > 0 ||
            newErrors.currentYear.length > 0 ||
            newErrors.companyPhone.length > 0 ||
            newErrors.companyEmail.length > 0 ||
            newErrors.addressLine1.length > 0 ||
            newErrors.city.length > 0
        ) {
            isValid = 0;
        }

        return isValid;
    }

    function handleInputChange(event) {

        let { name, value } = event.target;

        let newFormInput = {
            ...formInput,
            [name]: value
        };

        setFormInput(newFormInput);
        setShowSave(true);
        updateErrors(name);

    }
    function handleAddressChange(event) {

        let { name, value } = event.target;

        let newAddressInput = {
            ...companyAddress,
            [name]: value
        };
        setShowSave(true);
        setCompanyAddress(newAddressInput);
        updateErrors(name);
    }

    function handleCopyToChange(event, values) {
        setOrderConfirmationCopyTo(values);
    }

    async function handleFileChange(event) {
        file.current = event.target.files[0];
        if (event.target.files[0]) {
            const companyLogo = file.current ? await s3Upload(file.current) : null;

            let newFormInput = {
                ...formInput,
                ['companyLogo']: companyLogo
            };

            setFormInput(newFormInput);
        }
        setShowSave(true);
    }

    async function handleSubmit() {
        let isValid = validateForm();

        if (isValid) {
            if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
                alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE / 1000000} MB.`);
                return;
            }
            setIsLoading(true);
            actions.onSetIsLoading(true);
            try {

                formInput.companyAddress = companyAddress;
                formInput.orderConfirmationCopyTo = orderConfirmationCopyTo;

                let res = null;
                if (id === null) {
                    res = await saveCompany({ ...formInput });
                } else {
                    res = await updateCompany(id, { ...formInput });
                }

                if (isSupperAdmin) {
                    actions.onGetCompanies();
                } else {
                    actions.onGetCompany(id);
                }

                actions.onSetIsLoading(false);

            } catch (e) {
                onError(e);
                setIsLoading(false);
                actions.onSetIsLoading(false);
            }
        }
    }

    return (
        <div className="EmailSettingsForm" >
            <Box sx={{ flexGrow: 1, marginBottom: 2, marginTop: 2 }} style={styles.whiteBox}>
                {/* <h2 className="pb-3 mb-3 border-bottom"> {'Product Create Form'}</h2> */}
                <Grid container spacing={3}>

                    <Grid item xs={6} sm={6} md={6}>

                        <TextField
                            error={errors.storeName !== '' ? true : false}
                            id="outlined-error"
                            name="storeName"
                            helperText={errors.storeName}
                            label={"Company Name"}
                            value={formInput.storeName}
                            onChange={handleInputChange}
                            fullWidth
                        />

                    </Grid>

                    <Grid item xs={6} sm={6} md={6}>

                        <TextField
                            error={errors.fromEmail !== '' ? true : false}
                            id="outlined-error"
                            name="fromEmail"
                            helperText={errors.fromEmail}
                            label="From Email"
                            value={formInput.fromEmail}
                            onChange={handleInputChange}
                            fullWidth
                        />

                    </Grid>

                    {isSupperAdmin &&
                        <Grid item xs={6} sm={6} md={6}>
                            <TextField
                                error={errors.currentYear !== '' ? true : false}
                                id="outlined-error"
                                name="currentYear"
                                type="number"
                                helperText={errors.currentYear}
                                label="Business Year"
                                value={formInput.currentYear}
                                onChange={handleInputChange}
                                fullWidth
                            />

                        </Grid>
                    }

                    {isSupperAdmin &&
                        <Grid item xs={6} sm={6} md={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Billing Company</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="billingCompany"
                                    value={formInput.billingCompany}
                                    label="Billing Company"
                                    onChange={handleInputChange}
                                >
                                    {choices.map((s, index) =>
                                        <MenuItem key={index} value={s.value}>{s.name}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                    }

                    <Grid item xs={6} sm={6} md={6}>

                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Cost Change Method</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="inventoryCostChangeMethod"
                                value={formInput.inventoryCostChangeMethod}
                                label="Cost Change Method"
                                onChange={handleInputChange}
                            >
                                {inventoryCostChangeMethods.map((s, index) =>
                                    <MenuItem key={index} value={s.value}>{s.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>

                    </Grid>

                    <Grid item xs={6} sm={6} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Phone Country Code</InputLabel>
                            <Select
                                disabled={false}
                                error={errors.phoneCountryCode !== '' ? true : false}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="phoneCountryCode"
                                defaultValue={formInput.phoneCountryCode}
                                label="Phone Country Code"
                                onChange={handleInputChange}
                            >
                                {areacodes.map((c, index) =>
                                    <MenuItem key={index} value={c.code}>{c.country} <small>(+{c.code})</small></MenuItem>
                                )}

                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6} sm={6} md={6}>
                        <TextField
                            error={errors.companyPhone !== '' ? true : false}
                            id="outlined-error"
                            name="companyPhone"
                            helperText={errors.companyPhone}
                            label="Company Phone"
                            value={formInput.companyPhone}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={6} sm={6} md={6}>
                        <TextField
                            error={errors.companyEmail !== '' ? true : false}
                            id="outlined-error"
                            name="companyEmail"
                            helperText={errors.companyEmail}
                            label="Company Email"
                            value={formInput.companyEmail}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={6} sm={6} md={6}>
                        <TextField
                            // error={errors.companyTaxID !== '' ? true : false}
                            id="outlined-error"
                            name="companyTaxID"
                            // helperText={errors.companyTaxID}
                            label="Company Tax ID"
                            value={formInput.companyTaxID}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>


                    <Grid item xs={6} sm={6} md={6}>
                        <Autocomplete
                            // key={new Date()}
                            filterSelectedOptions
                            multiple
                            id="tags-filled"
                            options={orderConfirmationCopyTo.map((option) => option)}
                            value={orderConfirmationCopyTo}
                            freeSolo
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip key={index} variant="outlined" label={option} {...getTagProps({ index })} />
                                ))
                            }
                            onChange={handleCopyToChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    type={'email'}
                                    name="orderConfirmationCopyTo"
                                    label="Copy To Emails"
                                    placeholder="emails"
                                />
                            )}
                        />
                    </Grid>

                    {/* start */}
                    <Grid container item spacing={3}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography gutterBottom variant="body1">
                                Store Address
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                                error={errors.addressLine1 !== '' ? true : false}
                                id="outlined-error"
                                name="addressLine1"
                                helperText={errors.addressLine1}
                                label="Address Line One"
                                value={companyAddress.addressLine1}
                                fullWidth
                                onChange={handleAddressChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                                id="outlined-error"
                                name="addressLine2"
                                label="Address Line Two"
                                value={companyAddress.addressLine2}
                                fullWidth
                                onChange={handleAddressChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid container item spacing={3}>

                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                                error={errors.city !== '' ? true : false}
                                id="outlined-error"
                                name="city"
                                helperText={errors.city}
                                label="City"
                                value={companyAddress.city}
                                fullWidth
                                onChange={handleAddressChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>

                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">State</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="state"
                                    value={companyAddress.state}
                                    label="State"
                                    onChange={(e) => {
                                        handleAddressChange(e);
                                    }}
                                >
                                    {states.map((s, index) =>
                                        <MenuItem key={index} value={s}>{s}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                                id="outlined-error"
                                name="zipCode"
                                label="Zip Code"
                                value={companyAddress.zipCode}
                                fullWidth
                                onChange={handleAddressChange}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <Form.Group controlId="file">
                                <Form.Label>Business Logo</Form.Label>
                                <ActionAreaCard
                                    key={formInput.companyLogo}
                                    attachment={formInput.companyLogo}
                                />
                                <br />
                                <Form.Control onChange={handleFileChange} type="file" />
                            </Form.Group>
                        </Grid>

                    </Grid>

                    {/* End */}

                </Grid>
            </Box>

            {showSave && <LoaderButton
                style={{
                    marginTop: 10,
                    marginBottom: 10,
                    //marginLeft: 8
                }}
                block
                type="submit"
                size="sm"
                variant="primary"
                isLoading={isLoading}
                onClick={() => {
                    handleSubmit();
                }}
            >
                Save
            </LoaderButton>}

        </div>
    );
}

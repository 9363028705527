import { API } from "aws-amplify";
import {
    updateProduct,
    getProduct
} from "./products";
var moment = require('moment');


/**
* Function for getting all product received 
* @param {function} getAllProductsReceived
*/
export const getAllProductsReceived = async (companyId) => {

    let response;

    try {
        //add cid
        response = await API.get("adminAPI", `/admin/products_receiveds/${companyId}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function to remove product received
* @param {function} removeProductReceived
*/
export const removeProductReceived = async (id) => {

    let response;

    try {

        response = await API.del("adminAPI", `/admin/products_received/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};



/**
* Function for getting a product received
* @param {function} getProductReceived
*/
export const getProductReceived = async (id) => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/products_received/${id}`);

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for getting a product received history
* @param {function} getProductReceivedHistory
*/
export const getProductReceivedHistory = async (id) => {

    let response;

    try {

        response = await API.get("adminAPI", `/admin/products/products_received/${id}`);

    } catch (err) {
        console.log('error', err);
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for saving  a product received
* @param {object} data  
* @param {function} saveProductReceived 
*/
export const saveProductReceived = async (data) => {

    let response;

    try {

        response = await API.post("adminAPI", "/admin/products_received", {
            body: data
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};


/**
* Function for updating a product received
* @param {function} updateProductReceived 
*/
export const updateProductReceived = async (id, data) => {

    let response;

    try {

        response = await API.put("adminAPI", `/admin/products_received/${id}`, {
            body: data
        });

    } catch (err) {
        throw 'Cannot connect';
    }

    return response;
};

/**
* Function for receiving product
* @param {function} receiveProduct 
*/
export const receiveProduct = async (id, productId, userId, data, costChangeMethod) => {

    let response = {
        status: false
    };

    try {
        data.receiveStatus = 'received';
        data.receivedBy = userId;
        data.receivedDate = new Date();

        const updateRes = await updateProductReceived(id, { ...data });

        if (updateRes.status) {
            const product = await getProduct(productId);

            //Add Quantity to product
            product.productQuantity = +product.productQuantity + +data.productQuantity;
            product.availableQuantity = +product.availableQuantity + +data.productQuantity; //Available quantity from all vendors

            //Calculate product cost
            let totalQuantity = +product.productQuantity + +data.productQuantity;
            let totalCurrentProCost = product.productCost * +product.productQuantity;
            let totalCost = totalCurrentProCost + data.productTotal;
            
            switch (costChangeMethod) {

                case 'FIFO':
                    product.productCost = data.productUnitPrice;
                    break;
                case 'AVG':
                    product.productCost = totalCost / totalQuantity;
                    break;
                default:
                    break;
                    
            }

            product.receivedDate = new Date();
            product.productOrigin = data.productOrigin;

            response = await updateProduct(productId, { ...product });
        }

    } catch (err) {
        console.log('err', err);
        throw 'Cannot connect';
    }

    return response;
};

export const productReceiveData = () => {
    let data = {
        productId: "",
        productDescription: "",
        receiveStatus: "pending",
        categoryId: "",
        productOrigin: "",
        packagingNumber: "",
        receivedDate: "",
        receivedBy: "",
        createdBy: "",
        productQuantity: 0,
        productQuantityType: "",
        productUnitPrice: 0,
        isReturned: false
    };
    
    return data;
};




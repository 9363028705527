import * as React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import LoaderButton from "../../components/LoaderButton";
import { useAppContext } from "../../lib/contextLib";
import { onError } from "../../lib/errorLib";
import { Text } from '../../services/translator';

export default function SelectYear({ btColor, btTitle, inputLabel, modelTitle, onChange, onSave, formInput }) {

    const {
        yearsList
    } = useAppContext();
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [errors, setErrors] = React.useState({
        currentYear: ''
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function handleInputChange(event) {

        let { name, value } = event.target;

        let newFormInput = {
            ...formInput,
            [name]: value
        };
        onChange(newFormInput);
        updateErrors(name);
    }

    function updateErrors(name) {

        let newErrors = {
            ...errors,
            [name]: ''
        };

        setErrors(newErrors);
    }

    function validateForm() {
        let newErrors = { ...errors };
        let isValid = 1;

        if (formInput.currentYear == '') {
            newErrors.currentYear = 'Field is required.';
        } else {
            newErrors.currentYear = '';
        }

        setErrors(newErrors);

        if (
            newErrors.currentYear.length > 0
        ) {
            isValid = 0;
        }

        return isValid;
    }

    async function handleSubmit() {
        let isValid = validateForm();
        setIsLoading(true);
        if (isValid) {
            try {
                onSave();
                setIsLoading(false);
                setOpen(false);
            } catch (e) {
                onError(e);
                setIsLoading(false);
            }
        }

    }
    return (
        <div style={{ marginLeft: 10 }}>
            <Button variant="outlined" onClick={handleClickOpen} color={btColor} style={{ marginRight: 10 }}>
                {btTitle}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" >
                    {modelTitle}
                </DialogTitle>
                <DialogContent style={{ minWidth: 600 }}>
                    <DialogContentText id="alert-dialog-description">
                        <Grid
                            container
                            spacing={2}
                            style={{ marginTop: 2 }}
                        >
                            <Grid item xs={12} sm={12} md={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">{inputLabel}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="currentYear"
                                        value={formInput.currentYear}
                                        label={inputLabel}
                                        onChange={handleInputChange}
                                    >
                                        {yearsList.map((type, index) =>
                                            <MenuItem key={index + type} value={type}>{type}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>

                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}><Text>Cancel</Text></Button>
                    <LoaderButton
                        type="submit"
                        size="sm"
                        variant="primary"
                        isLoading={isLoading}
                        onClick={handleSubmit}
                    >
                        <Text>Save</Text>
                    </LoaderButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}
